.modal {
    padding: 16px 18px;
    text-align: center;
    font-size: 18px;
}

.modal   img.dangerIcon {
    width: 7rem;
    height: 6rem;
    margin: 1rem auto 3rem;
}

.modal img.removeProfilePicture {
    max-width: 18rem;
    margin: 0 auto 2rem;
}

.modal > .header {
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid gray;
    text-align: center;
    font-size: 18px;
}

.modal > .content {
    width: 100%;
    padding: 10px 5px;
    letter-spacing: 0.5px;
    line-height: normal;
}

.modal > .actions {
    width: 100%;
    margin: auto;
    padding: 10px 5px;
    text-align: center;
}

.modal > .close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    padding: 4px 7px;

/* border: 1px solid #cfcece; */
    border-radius: 18px;
    background: #ffffff;
    color: #384157;
    font-size: 2.5rem;
    line-height: 20px;
    cursor: pointer;
}

.popup-content.success-content {
    background-color: #ddfbaf;
}

.popup-content.error-content {
    background-color: #f6abab;
}

.popup-content.warning-content {
    background-color: #ffe88f;
}

.actions {
    display: flex;
    justify-content: center;
    margin-top: 28px;

    gap: 10px;
}
