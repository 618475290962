@import '../../assets/scss/variable.scss';

.message {
    margin: 0.3rem 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 1.6rem;

    @media screen and (min-width: 667px) {
        margin: 0.3rem 0;
        padding: 0.5rem;
        border-radius: 0.5rem;
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 1rem;
    }
}

.user {
    align-self: flex-end;
    max-width: 80%;
    margin: 1rem 0;
    padding: 1.25rem 1rem;
    border-bottom-right-radius: 0;
    background-color: #0b93f6;
    color: $light-color;
    font-size: 1.25rem;
}

.assistant {
    align-self: flex-start;
    width: 100%;
    max-width: 80%;
    padding: 1.25rem 1rem;
    border-bottom-left-radius: 0;
    background-color: $gray;
    font-size: 1.25rem;

    &.chatPage {
        max-width: 100%;
        padding: 0;
        margin-bottom: 0;
        padding: 1.25rem 1rem;
        background-color: $gray;
    }
}
