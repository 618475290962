.customScroll {
  height: calc(100vh - 70px);
  overflow-y: auto;
  @media screen and (min-width: 667px) {
    &.dashboard {
      height: calc(100vh - 120px);
    }
  }
}
.customScroll::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 0.3rem;
}

.mb-1_5 {
  margin-bottom: 1.5rem;
}
