@import '../../../../assets/scss/variable.scss';

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2.5rem;

    gap: 1.5rem;

    @media screen and (min-width: 667px) {
        box-shadow: -10px 0 10px 0 rgb(239, 239, 243);
    }
}

.placeholder {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.switchWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 4.5rem;
    padding: 1.25rem;
    border-radius: 0.8rem;
    background-color: $light-color;

    gap: 1.5rem;
}

.settingsConnection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    border-radius: 0.8rem;
    background-color: $light-color;
    overflow-y: scroll;
    gap: 1.5rem;
}

.subtitle {
    font-weight: 500;
    font-size: 1.75rem;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    gap: 1.5rem;
}

.reconnect {
    display: flex;
    flex-direction: column;

    gap: 1.5rem;
}

.newConnection {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    gap: 1.5rem;
}

.apiInputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 1rem;

    input {
        width: 100%;
        margin-top: 0;
    }
}

.columnDirection {
    flex-direction: column;
}

.actionBtn {
    margin: 10px auto 0;
    padding: 0.7rem 1.75rem;
}

.altegioLoginWrapper {
    display: flex;
}

.loaderContainer {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.infoText {
    padding: 1.25rem;
    border-radius: 0.5rem;
    background: $gradient-light-blue-purple;
    color: $light-color;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 3rem;

    a {
        color: #42E536;
        font-size: 1.6rem;
    }

    code {
        margin-right: 5px;
        font-size: 1.6rem;
    }

    svg {
        margin-right: 10px;
        cursor: pointer;

        @media screen and (min-width: 667px) and (max-width: 1280px) {
            width: 13px;
        }
    }
}
