@import "../../../../assets/scss/variable";

.container {
  width: 100%;
  height: calc(100% - 4.3rem);
  padding: 32px 17px 17px 17px;
}

.editFollowUpControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.canvasContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.reactFlow {
  background: #fff;
  border-radius: 8px;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
}
