.container {
  padding: 2.5rem;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #ccd5ed;
  overflow-y: hidden;
  // 38px is the height of the IntegrationHeader component
  height: calc((100% - 2.5rem) - 38px);

  @media screen and (min-width: 667px) {
    background-color: #e3e6ee;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: row;
    border-radius: 1.25rem 0 0 1.25rem;
  }
}

.loaderContainer {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}