@import '../../assets/scss/variable';

.buttonContainer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 0 0.5rem;
  background: $light-color;

  gap: 1.5rem;

  .applyButton,
  .cleanAllButton {
      width: 100%;
      padding: 10px;
      border-radius: 16px;

      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1;
      cursor: pointer;
  }

  .applyButton {
      border: none;
      background: $gradient-blue-to-purple-angled;
      color: $light-color;

      &:disabled {
          background: $gray-300;
          cursor: not-allowed;
      }
  }

  .cleanAllButton {
      border: 1px solid $purple;
      background-color: $light-color;
      color: $purple;

      &:disabled {
          border: 1px solid $gray-300;
          color: $gray-300;
          cursor: not-allowed;
      }
  }
}
