@import "../../../../../../../assets/scss/variable";

.containerTyping {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  padding: 1rem;
  padding-left: 0;
}

.botResponse {
  display: flex;
  align-items: center;

  font-size: 1.25rem;

  p {
    margin-left: 0.75rem;
  }

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.specialMsgBox {
  margin: 2px 0px 4px 0px;
  position: relative;
}

.specialMsgTextarea {
  border-radius: 8px;
  padding: 16px 28px 16px 16px;
  resize: vertical;
  border-top-left-radius: 0;
  font-size: 1.25rem;
}

.specMessageIconSave {
  position: absolute;
  top: 0;
  right: 0;
  color: #439929;
  cursor: pointer;
}

.specialMsg {
  border-radius: 8px;
  border-top-left-radius: 0;
  margin: 0 0 0.3rem 0;
  padding: 1.25rem 1rem;
  font-size: 1.25rem;
  background-color: #cde8b5;
}

.messageDate {

  color: $gray-500;
  padding: 0;
  font-size: 0.9rem;
  margin: 0 0.3rem 0.5rem;
  font-weight: 600;
  background: none;
}
