@import "../../../assets/scss/variable";

.optimizeBtn {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 10px;
  & > svg {
    width: 24px;
    height: 24px;
  }

  &:disabled {
    & > svg {
      stroke: #e3e6ef;
    }
    &::after {
      display: none;
    }
  }
  &:hover {
    &::after {
      content: "Suggest instructions";
      position: absolute;
      // white-space: nowrap;
      top: 50%;
      left: -100%;
      transform: translate(-50%, -50%);
      background: linear-gradient(rgb(129, 177, 235), rgb(108, 72, 255));
      padding: 2px 5px;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 700;
      border-radius: 2px;
      color: white;
    }
  }
  @media screen and (min-width: 667px) {
    top: unset;
    bottom: unset;
    bottom: 5px;
    right: 5px;
  }
}

.textarea {
  flex-grow: 1;
  resize: none;
  width: 100%;
  border-radius: 0.5rem;

  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.8rem 0 0.8rem 1.25rem;
  margin-bottom: 1.5rem;
  background-color: $gray-300;
  border: 0.06rem solid $gray-medium;
  overflow-y: auto;
}

.textareaTitle {

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
}

.suggestInstractionsContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @media screen and (min-width: 667px) {
    flex-direction: row;
    gap: 20px;
  }
}

.suggestInstractionsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  @media screen and (min-width: 667px) {
    flex-grow: unset;
  }
}
