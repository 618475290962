.container {
  width: 418px;
}

.handle {
  background: #384157;
  width: 9px;
  height: 9px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-family: "Montserrat", sans-serif;
  background: #f0f2f4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #384157;
}

.editButton {
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 2px solid #384157;
  margin-left: 8px;
  padding-left: 8px;
}

.startConditionIcon {
  margin: 0 4px;
}