@import "../../../assets/scss/variable.scss";

.timePicker {
  background: $light-color;
  color: $gray-medium;
  min-height: 38px;
  width: 100%;
  & .react-time-picker__wrapper {
    border-radius: 0.5rem;
  }
}

.select {
  background: $light-color;
  margin-bottom: 1.5rem;
}

.row .my-select {
  width: 100%;
}
