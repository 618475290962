.headerContainer {
  display: grid;
  grid-template-columns: 1fr 20fr;
}

.headerWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 2fr 1fr;

  .headerCell {
    padding: 0.75rem 1.75rem;
    font-size: 1.2rem;
  }
}
