.container {
  width: 100%;
  min-height: 72px;
  padding: 14px 18px 12px;
  background: #e3e6ee;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.containerStartNode {
  min-height: 40px;
  border-radius: 0;
}