@import "../../assets/scss/variable";

.title {

  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: 0;
  text-align: left;
}
