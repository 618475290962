@import '../../assets/scss/variable';

.tabs {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    padding: 4px;
    border-bottom: 0.2rem solid #cccccc;
    border-radius: 16px;
    //position: sticky;
    background: $gray;

    gap: 0.5rem;

    > div {
        width: 50%;
    }

    button:not(.tabCount) {
        width: 100%;
    }
}

.tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.25rem;
    padding: 8px 10px;
    border-radius: 1.3rem;
    background: $light-color;
    color: $gray-500;

    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;

    @media screen and (max-width: 1280px) {
        font-size: 1.25rem;
    }

    @media screen and (max-width: 667px) {
        font-size: 1.1rem;
        height: 3rem;
    }

    &.active {
        background: $purple;
        color: $light-color;
    }
}

.tabCount {
    position: absolute;
    right: 5px;
    display: flex;
    display: grid;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 2.25rem;
    min-height: auto;
    margin: 6px;
    padding: 0.25rem;
    border-radius: 4px;
    background: $purple-light;
    color: $light-color;
    font-weight: 500;
    font-size: 1.2rem;
    transition: all 0.7s;

    aspect-ratio: 1 / 1;
    place-items: center;

    @media screen and (max-width: 667px) {
        min-width: 2rem;
        margin: 5px;
        padding: 0.25rem;
        font-size: 1.05rem;
    }

    & .unreadCount {
        opacity: 1;
    }

    & .tooltip {
        width: 0;
        height: 0;
        margin: -1px;
        opacity: 0;
    }
}

button.tabCount:hover {
    right: 5px;
    width: 50px;
    font-size: 1rem;
    line-height: 1.4;

    aspect-ratio: auto;

    @media screen and (max-width: 1280px) {
        right: 3px;
        width: 40px;
        font-size: 0.95rem;
    }

    @media screen and (max-width: 1023px) {
        right: 0;
        width: 30px;
        font-size: 0.8rem;
    }

    @media screen and (max-width: 768px) {
        right: -3px;
        width: 25px;
        font-size: 0.9rem;
        line-height: 1.15;
    }

    @media screen and (max-width: 450px) {
        right: 5px;
        width: 50px;
        font-size: 0.9rem;
        line-height: 1.15;
    }

    & .unreadCount {
        width: 0;
        height: 0;
        margin: -1px;
        opacity: 0;
    }

    & .tooltip {
        width: auto;
        height: auto;
        margin: 0;
        text-wrap: nowrap;
        opacity: 1;
    }
}
