.iconContainer {
  width: 4rem;
  height: 4rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  div {
    flex-shrink: 0;
  }
}

.input {
  margin-top: 0;
  margin-left: 1rem;
  width: 100%;
  padding: 1rem 1.5rem;
}
