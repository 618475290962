@import "../../assets/scss/variable";

.wrapper {
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: center;
  background: linear-gradient(to bottom, #81B1EB, #767DF6);
  height: 100vh;
  padding: 0;

  @media screen and (min-width: 667px) {
    flex-direction: row;
    display: flex;
    flex: 1;
    align-items: center;
    background: linear-gradient(to right, #81B1EB, #8096EC, #767DF6);
    height: 100vh;
    padding: 0 4rem;
  }
}

.authModal {
  display: flex;
  flex-direction: column;
  height: 59rem;
  width: 100%;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background-color: #E1DAFB;
  padding: 2.5rem 1.25rem 2.4rem 1.25rem;
  @media screen and (min-width: 667px) {
    padding: 5rem 5rem 0 5rem;
    display: flex;
    flex-direction: column;
    width: 55rem;
    height: 59.25rem;
    border-radius: 1.25rem;
    background-color: #E1DAFB;
  }
}

.imgWrapper {
  position: relative;
  display: flex;
  height: 20rem;
  width: 100%;
  font-size: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 667px) {
    display: flex;
    flex: 1;
    height: 0;
    width: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.signIn {
      width: 14.1rem;
      height: 14.1rem;
      @media screen and (min-width: 667px) {
        margin-top: 3.7rem;
        width: 48.7rem;
        height: 48.4rem;
      }
    }

    &.signUp {
      width: 16rem;
      height: 13.5rem;
      @media screen and (min-width: 667px) {
        margin-top: 5rem;
        width: 55.4rem;
        height: 45.5rem;
      }
    }
  }

  img.active {
    &.signIn {
      opacity: 1;
      width: 14.1rem;
      height: 14.1rem;
      @media screen and (min-width: 667px) {
        opacity: 1;
        margin-top: 3.7rem;
        width: 48.7rem;
        height: 48.4rem;
      }
    }

    &.signUp {
      opacity: 1;
      width: 16rem;
      height: 13.5rem;
      @media screen and (min-width: 667px) {
        opacity: 1;
        margin-top: 5rem;
        width: 55.4rem;
        height: 45.5rem;
      }
    }
  }
}

.statusAuth {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  background-color: $light-color;
  border-radius: 0.5rem;
  //margin-bottom: 5rem;
  margin-bottom: 3rem;
}

.statusAuth.register {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
  background-color: $light-color;
  border-radius: 0.5rem;
  margin-bottom: 0;
}

.authButton {
  text-transform: uppercase;
  background-color: $light-color;
  border-radius: 0.5rem;
  margin-top: 2.5rem;

  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  padding: 1.1rem 2.5rem;
}

.authButton.error {
  margin-top: 1rem;
}

.authButton:hover {
  background: linear-gradient(to bottom, #7B89F1, #4F52FF);
  color: $light-color
}

.authStatusButton.active {
  background: linear-gradient(to bottom, #7E92ED, #767DF6);
  color: $light-color;
}

.authStatusButton {
  width: 100%;
  background-color: $light-color;
  color: $primary-color;
  border-radius: 0.5rem;

  font-size: 0.9rem;
  padding: 0.5rem;
  line-height: 1.1rem;
  font-weight: 700;
}

.forgotPassword {

  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: center;
  margin-top: 2.5rem;
  cursor: pointer;
  @media screen and (min-width: 667px) {
    font-size: 0.9rem;
  }
}

.forgotPassword.active {

  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
  margin-top: 2.5rem;
  cursor: pointer;
}

.termOfUse {

  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: center;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  a {
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: underline;

    @media screen and (min-width: 667px) {
      text-decoration: underline;
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 667px) {
    font-size: 0.8rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  &__bottom {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0;
    text-align: center;

    a {
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: underline;

      @media screen and (min-width: 667px) {
        font-size: 0.8rem;
        text-decoration: underline;

      }
    }

    @media screen and (min-width: 667px) {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      font-size: 0.8rem;
    }
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }

  @media screen and (min-width: 667px) {
    font-size: 0.8rem;
  }
}

.lineText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-top: 3.75rem;
  margin-bottom: 2.5rem;
  @media screen and (min-width: 667px) {
    position: relative;
  }
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.1rem;
  background-color: $light-color;

  @media screen and (min-width: 667px) {
    left: -5rem;
    right: -5rem;
  }

}

.text {
  position: relative;
  z-index: 1;
  background-color: #E1DAFB;
  padding: 0 0.5rem;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
}

.socialButton {
  background-color: transparent;
  border: 0.1rem solid #7C8CF0;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;

  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 700;
  padding: 0.75rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1.5rem;
  }
}

.arrowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.arrowButton {
  background: linear-gradient(to top, #7B89F1, #4F52FF);
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;

  svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.modalOpen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
}

.modalContent {
  width: 31rem;
  height: 27.5rem;
  background-color: $light-color;
  padding: 2.5rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1 {

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: 0;
  }

  p {

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0;
  }

  img {
    width: 20.7rem;
    height: 10.5rem;
  }

  &__img {
    width: 26.25rem;
    height: 14.4rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1DAFB;
    margin: 1.25rem 0;
  }

  .modalHeader {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      cursor: pointer;
    }
  }
}

.loaderContainer {
  display: flex;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}