@import "../../assets/scss/variable";

.testBot {
  overflow: auto;
  height: 58rem;
  margin: 1.25rem 1.25rem 2rem 1.25rem;
  border-radius: 1.25rem;
  background-color: #e2daff;
  @include flex-center(column, space-between, inherit);

  @media screen and (min-width: 667px) {
    overflow: auto;
    width: 48rem;
    margin: 2.5rem 2.5rem 2.5rem 0;
    border-radius: 1.25rem;
    background-color: #e2daff;
    @include flex-center(column, space-between, inherit);
  }
}

.blurred {
  filter: blur(5px);
  cursor: default;
}

.title {
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 2.5rem;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background-color: $gray-300;
  @include flex-center(row, space-between, center);
}

.titleBot {
  text-align: left;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.8rem;
}

.imageWrapper {
  padding: 1rem;
  cursor: pointer;
}

.closeIcon {
  width: 2rem;
  height: 2rem;
}

.chatList {
  flex: 1;
  padding: 1rem;
  @include flex-center(column, flex-start, flex-end);
}

.botResponse {
  margin: 1rem auto 1rem 1.25rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;

  p {
    margin-left: 0.75rem;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.wrapper {
  position: sticky;
  bottom: 0;
  font-size: 1.25rem;
}

.icon {
  position: absolute;
  top: 50%;
  right: 2rem;
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }

  &.active {
    cursor: auto;

    path {
      fill: #a9a9a9;
    }
  }
}

.textarea {
  display: flex;
  overflow: hidden;
  width: 100%;
  max-height: 16rem;
  padding: 1rem 3.8rem 1rem 1.5rem;
  outline: none;
  border-radius: 1.25rem;
  white-space: pre-wrap;
  resize: none;
}