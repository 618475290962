@import "../../assets/scss/variable";

.input {
  border-radius: 0.5rem;
  margin-top: 2.5rem;

  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 600;
  padding: 1rem 2.5rem;
  border: 0.1rem solid #7c8cf0;
}

.inputWithTitle {
  margin-top: 0;
}

.password {
  letter-spacing: 0.7rem;
}

.error {
  border-color: $danger;
}

.errorText {

  color: $danger;
  font-size: 0.9rem;
  margin-top: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
}

.title {

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
}

.defaultTitle {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.375rem;
}

.aiInput {
  border-radius: 0.5rem;

  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.8rem 0 0.8rem 1.25rem;
  margin-bottom: 1.5rem;
  background-color: $gray-300;
  border: 0.06rem solid $gray-medium;
}

.connected {
  border-radius: 0.5rem;

  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.8rem 0 0.8rem 1.25rem;
  background-color: $gray-300;
  border: 0.06rem solid $gray-medium;
}

.textarea {
  resize: none;
  width: 100%;
  border-radius: 0.5rem;

  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.8rem 0 0.8rem 1.25rem;
  margin-bottom: 1.5rem;
  background-color: $gray-300;
  border: 0.06rem solid $gray-medium;
  overflow-y: auto;
}

.select {
  background-color: $gray-300;
  color: $gray-medium;

}

textarea::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}

textarea::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 0.3rem;
}
