@import '../../../../assets/scss/variable';

.metadataSettings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.25rem;
    border-radius: 0.8rem;
    background-color: $light-color;

    gap: 1.25rem;

    @media screen and (max-width: 667px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.75rem;
    }

    .switchContainer {
        display: flex;
        align-items: center;
        flex-direction: column;

        gap: 1rem;
    }

    .switchLabel {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;

        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5;
        cursor: pointer;

        gap: 8px;
    }

    .btnContainer {
        @media screen and (max-width: 667px) {
            margin: 0 auto;
        }
    }
}
