@import "../../../../assets/scss/variable.scss";

.customSelect {
  position: relative;
  width: 100%;
  height: 3.6rem;
}

.selectSelected {
  color: $gray-medium;

  font-size: 1.14rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.9rem 1.4rem;
  border: 1px solid #1e1e1e;
  border-radius: 0.6rem;
  height: 100%;
  cursor: pointer;
  text-align: left;
  position: relative;

  &.selectArrowActive .selectedArrow {
    transform: rotate(180deg);
  }
}

.selectedArrow {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  transition: transform 0.2s;
  width: 2.4rem;
  height: 2.4rem;
}

.selectItems {
  position: absolute;
  background: $light-color;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0.65rem;
  box-shadow: 0px 5px 15px 0px #2c272733;
  max-height: 180px;
  overflow-y: scroll;
  border-top: none;

  & div {
    color: $gray-medium;
    padding: 10px;
    cursor: pointer;

    font-size: 1.14rem;
    font-weight: 600;
    line-height: 1.5;
    transition: background 0.2s;
  }

  & div:hover {
    background: #e5e7fb;
  }
}

.disabled {
  background: #f5f5f5;
  color: #b3b3b3;
  cursor: not-allowed;
}
