.numberItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    margin: 0;
    font-size: 20px;
    cursor: pointer;
}

ul.clockNumbers {
     li {
        list-style: none;
    }

    li::before {
        display: none;
    }

    li:nth-child(odd) {
        opacity: 50%;
        filter: blur(1px);
    }
}

.clockBackground {
    position: relative;
    background-color: white;
}

.clockShadow {
    border-radius: 0.5rem;
    background: linear-gradient(
    to bottom,
    rgba(227, 230, 239, 1),
    transparent 30%,
    transparent 70%,
    rgba(227, 230, 239, 1)
    );
}

.clockBackground:nth-child(2) {
    &::before {
        content: ':';
        position: absolute;
        top: 41px;
        left: -7px;
        font-weight: 700;
        font-size: 15px;
    }
}
