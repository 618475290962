@import '../../assets/scss/variable';

.wrapper {
    @include flex-center(column, center, center);
    width: 100%;
    height: 40vh;
    margin-top: 1.2rem;
    //border: 1px solid $gray-300;
    border-radius: 1.5rem;

    gap: 3rem;

    img {
        width: 16rem;
        height: auto;
    }

    .description {
        color: #7d8aab;

        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

    .title {

        font-weight: 700;
        font-size: 2.25rem;

        @media screen and (max-width: 1023px) {
            font-size: 1.6rem;
        }
    }
}



