@import "../../assets/scss/variable";

.base {

  color: $gray-500;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: normal;
  margin: 0;
}

.caption {

  color: $blue-800;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: normal;
}

.caption2 {
  font-size: 1.5rem;

  font-weight: 500;
  color: $primary-color;
}

.body2 {

  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.body3 {

  font-size: 0.9375rem;
  font-weight: 400;
  color: $gray-500;
}

.body4 {

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.375;
  color: #7D8AAB;
}

.h2 {

  font-size: 2rem;
  color: #1e1e1e;
  font-weight: 700;
}

.h3 {

  font-size: 1.5rem;
  font-weight: 700;
  color: #1e1e1e;
}

.h4 {

  font-size: 1.125rem;
  font-weight: 600;
  color: $primary-color;
}

.h5 {
  font-size: 1.25rem;
  font-weight: 700;

  color: #1e1e1e;
}

.title {

  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: 0;
  text-align: left;
  color: $primary-color;
}

.error {
  color: $danger;
}
