@import '../../assets/scss/variable';

.container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 6rem);
    margin: 2.2rem 3.3rem 2.2rem 1.9rem;
}

.displayNone {
    display: none !important;
}

.wrapper {
    position: relative;
    display: flex;
    overflow-y: scroll;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    padding: 4rem 0;
    border-radius: 1.25rem;
    background-color: $gray-300;
    gap: 12px;

    @media screen and (max-width: 667px) {
      padding: 2rem 0;
    }
}

.createFollowContainer {
    overflow: hidden;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.createFollowContainer.hidden {
    height: 0;
    opacity: 0;
}

.description {
    margin-top: 0.5rem;
    letter-spacing: 0;

    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.9rem;

    @media screen and (min-width: 667px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.titleContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    gap: 2rem;
}

.createButtonSave {
    margin-top: 0.5rem;
}

.createButtonSaveModal {
    align-self: center;
    margin-bottom: 0;
}

.createFollowHeader {
    margin-bottom: 1.5rem;
    margin-left: 5.2rem;
    color: #1e1e1e;
    text-align: left;

    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.22;
}

.createFollowWrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 4rem;
    border-radius: 1.25rem;
    background-color: $gray-300;
}

.followUpNameField,
.followUpNameFieldModal {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1053px;

    gap: 5px;

    & label {

        font-weight: 600;
        font-size: 1.14rem;
        line-height: 1.5;
    }

    & input,
    & input::placeholder {

        font-weight: 600;
        font-size: 1.14rem;
        line-height: 1.5;
    }

    & input {
        width: 100%;
        height: 43px;
        padding: 1rem 0.8rem;
        border: 1px solid #1e1e1e;
        border-radius: 0.75rem;
        background-color: $gray-300;
    }

    & input::placeholder {
        color: $gray-medium;
    }
}

.followUpNameFieldModal {
    & input {
        margin-bottom: 5px;
        border: 1px solid #c8cbd9;
        background-color: $light-color;
    }
}

.followUpsFilters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1053px;

    gap: 1.6rem;
}

.followUpsTable {
    width: 90%;

    @media screen and (max-width: 769px) {
        width: 95%;
    }
}

.followUpsTableHeaders {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 28% 11% 11% 11% 11% auto;

    @media screen and (max-width: 667px) {
        grid-template-columns: 25% 10% 10% 10% 10% auto;
    }

    & h3 {
        display: flex;
        align-items: flex-end;
        width: 10rem;
        color: $blue-800;

        font-weight: 600;
        font-size: 1.14rem;
        line-height: 1.5;

        @media screen and (max-width: 667px) {
            align-items: flex-start;
            height: 100%;
            margin-bottom: 2rem;
            transform: rotate(180deg);
            font-size: 1rem;
            writing-mode: vertical-rl;
        }
    }

    & h3:first-child {
        padding-left: 28px;

        @media screen and (max-width: 667px) {
            padding-right: 2rem;
            padding-left: 0;
        }
    }
}

.followUpsModal {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 100%;
    max-width: 334px;
    padding: 20px 15px;
    border-radius: 0.75rem;
    background-color: #eceaea;
    box-shadow: 0 4px 4px 0 #0000001a;
    transform: translate(-50%, -50%);
}

.followUpsModalContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    gap: 10px;

    & h2 {
        color: $blue-800;

        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.43;
    }
}

.followUpsList {
    & ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        gap: 12px;
    }

    & ul li::before {
        display: none;
    }
}

.closeCreateRowButton,
.openCreateRowButton {
    align-self: end;
    margin-bottom: 1.5rem;
}

.createFollowHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.addFollowUpError {
    margin-top: 0.5rem;
    color: $danger;

    font-weight: 600;
    font-size: 1.14rem;
    line-height: 1.5;
}

.title {
    margin: 2.5rem;
}

.loaderContainer {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    height: 100vh;
}
