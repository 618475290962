@tailwind base;
@tailwind components;
@tailwind utilities;

@import "normalize.css";
@import "assets/scss/fonts.scss";
@import "assets/scss/base.scss";
@import "assets/scss/common.scss";

.disableBody {
  overflow: hidden;
}
