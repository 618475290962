@import "../../../../../../../assets/scss/variable";

.replyMessageContainer {
  border-left: 4px solid $gray-300;
  padding-left: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  padding: 10px;
  background-color: #7dbdec;
  margin-left: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.replyHeader {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.4rem;
  color: $gray-500;
}

.replyText {
  color: $light-color;
    font-size: 1.55rem;
    line-height: 1.5;
}

.replyImage {
  border-radius: 10px;
  max-width: 120px;
  object-fit: contain;
  object-position: center;
  padding: 5px 0 0;

  @media screen and (max-width: 769px) {
    max-width: 100px;
  }
}
