@import "../../../../assets/scss/variable";

.container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 5.25rem;
  border: 1px solid #e2daff;
  width: 48%;
  border-radius: 0.5rem;
}

.value {
  font-size: 3rem;
  color: $purple;
  font-weight: 600;
}

.title {
  margin-top: 0.75rem;
  font-weight: 600;
}

.description {
  margin-top: 1rem;
  font-size: 1rem;
  color: $gray-500;
}
