.optionsButtonsContainer {
  margin-top: 1rem;
}

.title {
  margin: 0.5rem 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  button {
    width: 137px;
    min-width: unset;
    padding: 0;
  }
}
