.modalContent {
  background: #e3e6ee;
  border-radius: 20px;
}

.actionsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  display: block;
  height: 48px;
  padding: 0 14px;
  background: #e3e6ee;
  border: 1px solid #1e1e1e;
  border-radius: 8px;
  margin-top: 4px;
  width: 100%;
}

.textarea {
  height: 30vh;
  padding: 14px;
  overflow-y: scroll;
  resize: vertical;
}

.label {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0;
}

.subtitle {
  margin: 10px 0;
}

.selectLabel {
  margin-bottom: 4px;
}

.saveButton {
  margin-top: 1.25rem;
  width: fit-content;
  align-self: center;
}
