@import '../../assets/scss/variable.scss';

.functionCallContainer {
  margin: 0.5rem auto 0.5rem 0;
  padding: 0.5rem;
  border-left: 4px solid $purple;
  border-radius: 0.5rem;
  background-color: #f9f0ff;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f1eaff;
  }

  .functionCallHeader {
    cursor: pointer;
    gap: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .functionCallLabel {
    color: $purple;
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }

  .functionCallName {
    font-style: italic;
    font-size: 1.2rem;
  }

  .collapseButton {
    border: none;
    background: none;
    font-size: 1.2rem;
    line-height: 1;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: rotate(180deg);
    }
  }

  .functionCallBody {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.3s ease-in-out;
    max-height: 0;
    padding: 0;

    &.expanded {
      padding: 0.5rem;
    }

    .jsonBox {
      overflow-x: auto;
      padding: 0.5rem;
      border-radius: 0.25rem;
      background: $gray;
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
}
