@import '../../assets/scss/variable';

.valueContainer {
    display: flex !important;
    flex-wrap: nowrap!important;
    padding: 2px 0 2px 8px !important;

    @media screen and (max-width: 1023px) {
        padding: 2px 0 2px 5px !important;
    }
}

.selectedItems {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-weight: 400;
    font-size: 1.15rem;
    line-height: 1;
    color: $purple;

    gap: 0.5rem;

    span {
        padding: 1px 4px;
        border-radius: 4px;
        background: $purple-light;
        color: $light-color;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.2;
        gap: 4px;
    }
}

.selectedItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px  5px;
    border-radius: 8px;
    background: $purple-light;
    color: $light-color;

    gap: 4px;
}

.selectedCount {
    font-weight: bold;
}

.selectedValue {
    font-style: italic;
}

.customOption {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        padding: 1px;
        outline: none;
        border: 2px solid $secondary-color;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s, border-color 0.2s;

        appearance: none;

        @media screen and (max-width: 1023px) {
            width: 12px;
            height: 12px;
        }

        &:checked {
            border-color: $purple;
            background-color: $purple;

            &::after {
                content: '';
                display: block;
                width: 5px;
                height: 12px;
                border: solid $light-color;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);

                @media screen and (max-width: 1023px) {
                    width: 4px;
                    height: 8px;
                }
            }
        }

        &:hover {
            border-color: $purple;
        }
    }

    label {
        margin-left: 1.2rem;
        color: $gray-500;

        font-size: 1.2rem;
    }
}
