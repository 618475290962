@import "../../assets/scss/variable";

.container {
  margin: 1rem;
  @media screen and (min-width: 667px) {
    margin: 0;
    height: 80%;
  }
}

.chartWrapper {
  margin: 1rem;
  background-color: $gray-300;
  border-radius: 1rem;
  @media screen and (min-width: 667px) {
    margin: 2.5rem;
    // height: 38.4rem;

    border-radius: 1.25rem;
    padding: 2.5rem;
  }
}

.chartContainer {
  height: 100%;
  svg {
    height: 14rem;
    width: 34rem;
    background-color: $gray-300;
  }

  @media screen and (min-width: 667px) {
    svg {
      height: 29.25rem;
      width: 70.8rem;
      background-color: $gray-300;
    }
  }
}

.chartText {

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: $gray-500;

  &__welcome {

    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
    letter-spacing: 0;
    color: $gray-500;
    width: 100%;
    padding-top: 1.5rem;
    @media screen and (min-width: 667px) {
      padding-top: 0;
      width: 43%;
    }
  }

  &__title {
    text-align: left;
    margin: 2rem 0 1.2rem 2rem;
    padding-top: 2rem;

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: $gray-500;
    @media screen and (min-width: 667px) {
      text-align: left;
      margin: 0;
      margin-bottom: 2.5rem;

      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      letter-spacing: 0;
      color: $gray-500;
    }
  }
}

.chartInfoContainer {
  @media screen and (min-width: 667px) {
    display: flex;
  }
}

.chartInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1.2rem;
  margin-right: 1.2rem;

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 3.8rem;
    padding-left: 10px;
    padding-right: 10px;
    height: 1.7rem;
    border-radius: 1.25rem;
    background-color: #3588e9;

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0;
    margin-right: 0.75rem;
    color: $light-color;
  }
}

.timeframe {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 2.5rem 0;

  &__text {

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: $gray-500;
    margin: 0 2.5rem;
  }
}

.switchContainer {
  display: grid;
  gap: 1.5rem;
  margin: 1.5rem 1.5rem 0 1.5rem;
  @media screen and (min-width: 667px) {
    gap: 3.1rem;
    margin: 2.5rem 2.5rem 0 2.5rem;
    display: flex;
    align-items: center;
  }
}

.textInfo {
  margin: 0 2rem 2rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (min-width: 667px) {
    margin: 0;
  }
  @media screen and (min-width: 991px) {
    width: 100%;
  }
}

.chatDatepicker {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (min-width: 667px) {
    gap: 3rem;
    flex-direction: row;
    align-items: center;
  }
}

.loaderContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // margin: 1.95rem 0 0 0;

  button:first-child {
    // margin-bottom: 1.25rem;
  }

  @media screen and (min-width: 667px) {
    flex-direction: row;
    button:first-child {
      margin-bottom: 0;
    }
  }
}

.dashboardBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
  background-color: $light-color;
  color: $gray-500;
  border-radius: 0.5rem;

  font-size: 1.5rem;
  padding: 0.8rem;
  line-height: 1.1rem;
  font-weight: 600;
  @media screen and (min-width: 667px) {
    width: 22.5rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  &:hover {
    background: $gradient-blue-purple;
    color: $light-color;
  }
}

.IoIosWarning {
  height: 2rem !important;
  width: 2rem !important;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: rgb(239, 30, 36) !important;
}

.infoBox {
  @media screen and (min-width: 991px) {
    display: grid;
    grid-template-columns: calc(43% - 6.44rem) 57%;
    gap: 6.44rem;
  }
}

.calendarWrap {
  @media screen and (min-width: 667px) {
    margin-left: auto;
  }
}

.topUpButtonWrapper {
  margin: 2.5rem;
}