@import '../../assets/scss/variable';

.highlightMultipro.container {
    background-image: linear-gradient(to left top, #fefeff, #faf7ff);
    box-shadow: 0 0 20px 10px rgba(119, 126, 245, 0.5);
    transition: box-shadow 0.3s ease-in-out;
    transform: scale(1.05);

    @media screen and (max-width: 768px) {
        transform: none;
    }

    .titleWrapper {
        .title {
            color: $purple;
            font-weight: 700;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 1rem;
    padding: 2.5rem;
    border-radius: 1.25rem;
    background-color: $light-color;

    @media screen and (min-width: 769px) {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 2rem;
        border-radius: 1.25rem;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 23%;
        padding: 1.5rem;
        border-radius: 1.25rem;
    }

    @media screen and (max-width: 668px) {
        width: 100%;
    }
}

.headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .titleWrapper {
        display: flex;
        justify-content: space-between;

        .title {
            margin-top: 5px;
            margin-bottom: 3.25rem;
            color: $gray-500;
            text-transform: uppercase;

            font-weight: 600;
            font-size: 1.75rem;
            line-height: 2rem;

            @media screen and (max-width: 768px) {
                margin-bottom: 2.5rem;
                font-size: 1.5rem;
            }

            @media screen and (max-width: 667px) {
                margin-bottom: 1rem;
            }
        }

        .bussinesType {
            height: fit-content;
            margin-left: 15px;
            padding: 4px 12px;
            border-radius: 4px;
            background: #e2daff;
            color: $purple;

            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4rem;

            @media screen and (max-width: 768px) {
                padding: 4px 6px;
                font-size: 1.15rem;
            }
        }
    }

    .priceWrapper {
        display: flex;
        align-items: flex-end;


        .price {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            color: $purple;
            font-weight: 600;
            font-size: 4rem;
            line-height: 1;

            span {
                padding-right: 3px;
                color: $purple;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.2;
            }
        }

        .perMonth {
            margin-bottom: 0.2rem;
            margin-left: 1rem;
            color: $gray-500;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.4em;
        }
    }

    .description {
        min-height: 10px;
        margin-top: 1.5rem;
        color: $gray-500;
        letter-spacing: 0;

        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.4rem;

        span {
            line-height: 1.7rem;
            cursor: pointer;
        }
    }
}

.featuresList {
    margin-top: 3.25rem;
    border-top: 1px solid #e2daff;

    @media screen and (max-width: 768px) {
        margin-top: 2rem;
    }

    ul {
        padding-top: 1rem;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            margin-left: 0;
            padding-bottom: 1.1rem;
            color: $gray-500;
            text-align: left;

            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.5rem;

            gap: 10px;

            &:last-child {
                padding-bottom: 0;
            }

            @media screen and (max-width: 768px) {
                padding-bottom: 0.9rem;
            }

            &::before {
                content: none;
            }
        }
    }
}

.button {
    width: 100%;
    margin-top: 3.25rem;
    padding: 0.8rem;
    border-radius: 0.6rem;
    background: $gradient-blue-to-purple-angled;
    color: $light-color;
    letter-spacing: 0;

    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) and (max-width: 1023px) {
        margin-top: 2.5rem;
        padding: 0.7rem;
        border-radius: 0.5rem;
    }

    @media screen and (max-width: 667px) {
        margin-top: 2rem;
    }
}

.buttonCancel {
    width: 100%;
    margin-top: 4rem;
    padding: 0.8rem;
    border-radius: 0.6rem;
    background: $gray-300;
    color: $gray-500;
    text-transform: uppercase;
    letter-spacing: 0;

    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) {
        margin-top: 2.5rem;
        padding: 0.7rem;
        border-radius: 0.5rem;
    }
}

.proTitle {
    background: linear-gradient(to bottom, #5c4dff, #7572f7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: left;
    letter-spacing: 0;

    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;

    -webkit-text-fill-color: transparent;
}

.planDescription {
    background: linear-gradient(to bottom, #5c4dff, #7572f7);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: left;
    letter-spacing: 0;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 3rem;

    -webkit-text-fill-color: transparent;
}
