@import "../../assets/scss/variable";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 667px) {
    flex-direction: row;
  }
}

.assistantProfile {
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background-color: $gray-300;
  margin: 1.25rem;
  padding: 2rem;
  @media screen and (min-width: 667px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1.25rem;
    margin: 2.5rem;
    padding: 2.5rem;
    overflow: auto;
  }
}

.assistantProfile::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}

.assistantProfile::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 0.3rem;
}

.title {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  background-color: $gray-300;
}

.closeIcon {
  width: 2rem;
  height: 2rem;
}

.imageWrapper {
  padding: 1rem;
  cursor: pointer;
}

.wrapper {
  position: sticky;
  bottom: 0;

  font-size: 1.25rem;
}

.description {
  margin-top: 0.6rem;

  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: 0;
  margin-bottom: 1.25rem;
}

.saveOptimize {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
  background-color: $light-color;
  color: $gray-500;
  border-radius: 0.5rem;

  font-size: 1.5rem;
  padding: 0.8rem;
  line-height: 1.1rem;
  font-weight: 600;
  position: relative;

  @media screen and (max-width: 1023px) {
    height: 30px;
  }

  @media screen and (min-width: 667px) {
    width: 22.5rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  &:hover {
    background: $gradient-blue-purple;
    color: $light-color;
  }

  &.disabled {
    background: $color-light-gray;
    color: $gray-500;
  }

  &:disabled {
    background: $color-light-gray;
    color: $gray-500;

    &:hover {
      background: $color-light-gray;
      color: $gray-500;
      cursor: not-allowed;
    }
  }
}

.buttons {
  margin: 1.25rem 0 0 0;
}

.titleInputs {

  font-size: 2rem;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.titleBot {

  font-size: 2rem;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: 0;
  text-align: left;
}

.loaderContainer {
  border: 0.06rem solid $gray-medium;
  border-radius: 0.5rem;
  display: flex;
  height: 25rem;
  margin-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
}

.titleInput {

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
}

.modal {
  height: 25rem;
  background-color: $light-color;
  border-radius: 1.5rem;
  padding: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.25rem;
  @media screen and (min-width: 667px) {
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }
}

.modalButtonContainer {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 667px) {
    display: flex;
    flex-direction: row;
  }
}

.modalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fe3c3c;
  margin: 0.5rem;
  color: $light-color;
  border-radius: 0.5rem;

  font-size: 1.5rem;
  padding: 0.8rem;
  line-height: 1.1rem;
  font-weight: 600;
  @media screen and (min-width: 667px) {
    font-size: 1.25rem;
    font-weight: 700;
  }
}

.modalButton.cancel {
  background-color: #e5e5ea;
  color: $gray-500;
}

.saveOptimize:hover {
  background: $gradient-blue-purple;
  color: $light-color;
}

.btnBlock {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 667px) {
    display: flex;
  }
}

.wTitle {
  margin-bottom: 1.5rem;
  @media screen and (min-width: 667px) {
    margin-bottom: 0;
  }
}

.rowEl,
.rowAssistant {
  margin-bottom: 1.5rem;
  @media screen and (min-width: 667px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}

.rowEl {
  margin-bottom: 0;
}

.required {
  color: $danger;
}
