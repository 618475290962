@import '../../assets/scss/variable';

.container {
    margin: 1rem;

    @media screen and (min-width: 667px) {
        margin: 0;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

//padding: 2rem;
    border-radius: 1rem;

    //background-color: #e3e6ee;

    @media screen and (min-width: 667px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        margin: 2.5rem;

//padding: 2rem 2rem 2rem 2rem;
        border-radius: 0;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;

        //background-color: #e3e6ee;
    }
}

.description {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    color: $gray-500;
    letter-spacing: 0;

    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.9rem;

    @media screen and (min-width: 667px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.subTitle {
    margin-top: 1.5rem;
    color: #4c5876;
    text-align: center;

    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.4;
}

.integrations {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3rem;

    gap: 20px;

    @media screen and (min-width: 769px) and (max-width: 1023px) {
        gap: 10px;
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 1.5rem;

        gap: 10px;
    }
}

.needExtra {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 1.25rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #e2daff;

    @media screen and (min-width: 667px) {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2.5rem;
        margin-bottom: 2.75rem;
        margin-left: 2.5rem;
        padding: 2rem;
        border-radius: 0;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;

        img {
            width: 24.75rem;
            height: 18.75rem;
            margin-right: 4rem;
        }
    }
}

ul {
    color: $gray-500;
    letter-spacing: 0;

    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.9rem;

    @media screen and (min-width: 667px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    li {
        margin-top: 0.2rem;
        margin-left: 0.5rem;
        font-size: 1.5rem;

        @media screen and (min-width: 667px) {
            font-size: 1.25rem;
        }
    }
}

.authButton,
.paymentButton {
    width: 32rem;
    margin-top: 2.5rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    background-color: $light-color;
    color: $gray-500;
    text-transform: uppercase;
    letter-spacing: 0;

    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.7rem;

    @media screen and (min-width: 667px) {
        width: 22.5rem;
        margin-top: 2.5rem;
        padding: 0.7rem;
        border-radius: 0.5rem;
        background-color: $light-color;
        text-transform: uppercase;
        letter-spacing: 0;

        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.7rem;
    }
}

.needExtraContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 667px) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }
}

.mobileNeedExtra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
        width: 4.6rem;
        height: 3.5rem;
    }
}

.extraTextContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 667px) {
        display: flex;
        align-items: flex-start;
    }
}

.modalOpen {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);

    backdrop-filter: blur(5px);
}

.modalContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 31rem;
    height: 27.5rem;
    padding: 2.5rem;
    border-radius: 1rem;
    background-color: #e3e6ee;
}

.loaderContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.loaderText {
    margin-bottom: 5rem;

    font-weight: 600;
    font-size: 2.5rem;
}

@media screen and (max-width: 374px) {
    .needExtra {
        align-items: center;
        margin-top: 1rem;
        margin-left: 0;
        padding: 1rem;
    }

    ul {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    ul li::before {
        margin-right: 0.5rem;
        font-size: 1rem;
    }

    .authButton,
    .paymentButton {
        width: 100%;
        margin-top: 1.5rem;
        padding: 0.5rem;
        font-size: 1.25rem;
    }

    .needExtraContainer {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .mobileNeedExtra img {
        width: 3rem;
        height: 2.25rem;
    }

    .extraTextContainer {
        align-items: center;
    }
}

.loadingOverlay {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
}
