.header {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 667px) {
    align-items: center;
    grid-template-columns: 40.25rem auto;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectWrapper {
  max-width: 235px;
}
