@import '../../assets/scss/variable';

// !!! remove after moving to Tailwind src\pages\IntegrationsPage\components\IntegrationSettings\components\AuthConnection and src\pages\NotificationsPage\components\NotificationModal

.container {
// width: 23.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 22.7rem;
    padding: 1.5rem;
    border-radius: 1.25rem;
    background-color: $light-color;
    // margin-bottom: 2rem;
    // margin-left: auto;
    // margin-right: auto;

    @media screen and (min-width: 667px) {
// margin-left: 0;
        min-height: 22.5rem;
        padding: 1.2rem;
        // margin-right: 2.5rem;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;

    svg {
        width: 100px;
        height: 50px;
        margin-bottom: 1.5rem;

        @media screen and (min-width: 667px) {
            width: 60px;
            height: 30px;
        }

        @media screen and (min-width: 992px) {
            width: 80px;
            height: 40px;
        }

        @media screen and (min-width: 1024px) {
            width: 100px;
            height: 50px;
        }
    }

    img {
        width: 100px;
        height: 50px;
        margin-bottom: 1.5rem;
        object-fit: contain;

        @media screen and (min-width: 667px) {
            width: 60px;
            height: 30px;
        }

        @media screen and (min-width: 992px) {
            width: 80px;
            height: 40px;
        }

        @media screen and (min-width: 1024px) {
            width: 100px;
            height: 50px;
        }
    }
}

.title {
    margin-bottom: 0.9rem;
    text-align: center;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) {
        font-size: 1.25rem;
    }
}

.description {
    margin-bottom: 1.2rem;
    text-align: center;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) {
        font-size: 1.25rem;
    }
}

.button {
    width: 100%;
    padding: 0.8rem;
    border-radius: 0.6rem;
    background: $gradient-blue-purple;
    color: $light-color;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) {
        padding: 0.7rem;
        border-radius: 0.5rem;
    }
}

.loaderContainer {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.selectLanguagesModalContent {
    width: 100%;
}

@media screen and (max-width: 374px) {
    .container {
        width: 100%;
        height: auto;
        margin-right: 0;
        padding: 1rem;
    }

    .iconContainer {
        svg {
            width: 6rem;
            height: 6rem;
        }
    }

    .title {
        font-size: 1.25rem;
    }

    .description {
        font-size: 1.25rem;
    }

    .button {
        padding: 0.7rem;
        border-radius: 0.5rem;
        font-size: 1rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 0.5rem;
}

.link {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #3588e9;
}

.modalContent {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 2rem;
}

.input {
    width: 100%;
    margin-top: 1rem;
}

.connectButton {
    width: 30%;
    margin: 2rem auto 0;
}

.connected {
    background: #59d259;
}

.languageDisplay {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 1rem;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #666666;
    font-size: 14px;
}

.updateLangBtn {
    margin-bottom: 0.5rem;
}

.loadingLanguagesWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.siteLinksContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    a {
        padding: 0 0.25rem 0.25rem 0.5rem;
        color: #3588e9;
        overflow: hidden;
        max-width: 10rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
