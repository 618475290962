@import "../../assets/scss/variable";

.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3588e9;
  width: 11.2rem;
  border-radius: 4rem;

  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0;
  padding: 0.4rem 1rem;
  color: $light-color;
  @media screen and (min-width: 667px) {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    width: auto;
  }
}

.success {
  background-color: #6cce88;
}

.multipro {
  background: #E2DAFF;
  color: $purple;

}
