.bodyWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rowWrapper {
  display: grid;
  grid-template-columns: 1fr 20fr;
  min-height: 55px;
}

.dataContainer {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 2fr 1fr;
  background: linear-gradient(to bottom, #90caf9, #b39ddb);
  border-radius: 1rem;
}

.new {
  background: #ef9a9a;
}

.cell {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  padding: 0.75rem 1.75rem;

  p {
    font-family: "Montserrat", serif;
    font-weight: 600;
    font-size: 1.2rem;
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.actionsCell {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.icon {
  cursor: pointer;
  font-size: 1.25rem;
}

.newIcon {
  cursor: pointer;
  font-size: 1.75rem;
  color: #dd4247;
}

.newIconCell {
  display: flex;
  justify-content: center;
  align-items: center;
}
