@import '../../assets/scss/variable.scss';

.functionResultContainer {
  margin: 0.5rem auto 0.5rem 0;
  padding: 0.5rem;
  border-left: 4px solid #00c29f;
  background-color: #f0fff5;
  border-radius: 0.5rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e6fff0;
  }

  .functionCallHeader {
    cursor: pointer;
    gap: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .functionCallLabel {
    display: flex;
    align-items: center;
    color: #00c29f;
    font-weight: 600;
    font-size: 1.2rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .functionCallName {
    font-style: italic;
    font-size: 1.2rem;
  }

  .collapseButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1;
    transition: transform 0.3s;

    &:hover {
      transform: rotate(180deg);
    }
  }

  .functionCallBody {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    padding: 0;

  }
}