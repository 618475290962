@import '../../../assets/scss/variable';

.telegramConnection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 24px 0;


    gap: 24px;

    @media screen and (max-width: 1280px) {
        gap: 16px;
    }

    .inputWrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    label {
        padding-bottom: 8px;
        color: $gray-500;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.4;

        @media screen and (max-width: 768px) {
            font-size: 1.25rem;
        }
    }

    input {
        width: 460px;
        margin-top: 0.5rem;
        outline: none;

        @media screen and (max-width: 768px) {
            width: 100%;
            font-size: 1.15rem;
        }
    }

    .phoneInput {
        width: 460px;
        padding: 1rem 2.5rem;
        border: 0.1rem solid #7c8cf0;
        border-radius: 0.5rem;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1rem;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .connectButton {
        width: 460px;
        text-transform: none;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}

.loaderContainer {
    display: flex;
    flex: 1;
    height: 100vh;
    align-items: center;
    justify-content: center;
}