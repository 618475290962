// font family
$family_primary: "Montserrat", serif;
$family_secondary: "Gilroy";

// colors
$primary-color: #000000;
$secondary-color: #7e7e7e;
$accent-color: #2ca6e7;
$light-color: #ffffff;
$danger: #f5777d;

$purple: #777ef5;
$purple-hover: #5257b0;
$purple-light: #a7abf9;

// black
$color-light-black2: #7d8aab;

// gray
$gray: #f0f2f4;
$gray-hover: #dcdede;
$color-light-gray: #d9d9db;
$gray-300: #e3e6ee;
$gray-400: #4c5876;
$gray-500: #384157;
$blue-800: #1f384c;
$gray-medium: #727272;
$color-disabled: #9e9e9e;

// red
$color-light-red: #ffe5e7;

// green
$color-light-green: #e8ffeb;
$color-green: #7ae575;

// gradient
$gradient-blue-purple: linear-gradient(to bottom, #81b1eb, #6c48ff);
$gradient-blue-to-purple-angled: linear-gradient(
  146.18deg,
  #81b1eb 24.02%,
  #6c48ff 133.19%
);
$gradient-light-blue-purple: linear-gradient(to bottom, #3588e9, #777ff5);
$gradient-white-light-blue: linear-gradient(to bottom, #ffffff, #dae3fe);
$primary-gradient: linear-gradient(180deg, #81b1eb 6.299%, #6c48ff 188.402%);

// mixins

@mixin flex-center($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
