@import '../../assets/scss/variable';

.customDropdown {
    width: 100%;

    input {
        position: relative;
        width: 100%;
        padding: 8px 12px;
        outline: none;
        border: 1px solid $color-light-black2;
        border-radius: 1.25rem;
        color: $gray-500;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.4rem;

        @media (max-width: 769px) {
            padding: 6px;
        }
    }

    button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 16px;
        background: $gradient-blue-to-purple-angled;
        color: $light-color;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1;
        cursor: pointer;

        @media (max-width: 769px) {
            padding: 7px;
        }

        &:disabled {
            background: $gray-300;
            cursor: not-allowed;
        }
    }

    .title {
        display: flex;
        box-sizing: border-box;
        max-width: 100%;
        padding: 2px 0 2px 8px;
        color: $gray-500;
        font-size: 1.25rem;
        line-height: 1;
    }

    .optionsContainer {
        position: absolute;
        z-index: 1;
        top: 85%;
        right: 0;
        box-sizing: border-box;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 1.5rem;
        border: 1px solid $gray-300;
        border-radius: 16px;
        background-color: $light-color;
        box-shadow: 2px 2px 25px 0 rgba(0, 0, 0, 0.05);
        font-size: 1.25rem;

        @media (max-width: 1024px) {
            padding: 1rem;
        }

        .noItemsText {
            text-align: center;
            font-weight: 500;
            font-size: 1.25rem;
        }

        .customOption {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 1.15rem;
            padding: 5px;

            input[type='checkbox'] {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                padding: 1px;
                outline: none;
                border: 2px solid $secondary-color;
                border-radius: 4px;
                background-color: transparent;
                cursor: pointer;
                transition: background-color 0.2s, border-color 0.2s;

                appearance: none;

                @media screen and (max-width: 1023px) {
                    width: 12px;
                    height: 12px;
                }

                &:checked {
                    border-color: $purple;
                    background-color: $purple;

                    &::after {
                        content: '';
                        display: block;
                        width: 5px;
                        height: 12px;
                        border: solid $light-color;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);

                        @media screen and (max-width: 1023px) {
                            width: 4px;
                            height: 8px;
                        }
                    }
                }

                &:hover {
                    border-color: $purple;
                }
            }

            label {
                max-width: 8rem;
                margin-left: 1.2rem;
                color: $gray-500;
                word-break: break-word;
                font-size: 1.2rem;
            }

            .iconsWrapper {
                display: flex;
                margin-left: auto;

                gap: 1.25rem;

                @media (max-width: 1024px) {
                    gap: 0.5rem;
                }

                .editButton,
                .deleteButton {
                    padding: 0;
                    background: transparent;
                    cursor: pointer;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    .addNewBlock {
        padding: 8px;

        .addNewItemWrapper {
            display: flex;
            flex-direction: column;

            gap: 0.75rem;
        }
    }
}

// Editing mode
.editBlock {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    background: $gray;

    gap: 1.5rem;

    .editActions {
        display: flex;
        width: 100%;

        gap: 1rem;

        .saveButton {
            background: $gradient-blue-purple;
        }

        button {
            width: 100%;
        }
    }
}
