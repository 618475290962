@import '../../../../assets/scss/variable';

.loader {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.connectionBot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    border-radius: 0.8rem;
    background-color: $light-color;

    a {
        text-decoration: none;
    }
}

.connectionBotText {

    font-weight: 500;
    font-size: 1.5rem;

    a {
        color: $purple;

        font-weight: 500;
        font-size: 1.5rem;
    }
}

.token {
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;

    font-weight: 600;
    font-size: 1.7rem;
}

.saveChanges {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    background-color: transparent;

    @media screen and (min-width: 667px) {
        flex-direction: row;
        justify-content: flex-end;
        height: 4rem;
        margin-top: 1.5rem;
        padding: 0.5rem;
        border-radius: 0.8rem;
        background-color: $light-color;
    }
}

.saveChangesText {
    margin-bottom: 0.5rem;
    color: $primary-color;

    font-weight: 600;
    font-size: 1.5rem;

    @media screen and (min-width: 667px) {
        margin: 0;
        font-size: 1.25rem;
    }
}

.saveChangesButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 100%;
    margin-left: 1rem;
    border-radius: 0.25rem;
    background-color: $light-color;
    color: $purple;

    font-weight: 600;
    font-size: 1rem;
}

.saveChangesButton:hover {
    background-color: #dfd5ff;
}

.saveOptimize {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background: linear-gradient(to bottom, $light-color, #dae3fe);
    box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
    color: $gray-500;

    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.1rem;

    @media screen and (min-width: 667px) {
        width: 22.5rem;
        font-weight: 700;
        font-size: 1.25rem;
    }

    &.disabled {
        background: $color-light-gray;
        color: $gray-500;
    }

    &:hover {
        background: $gradient-blue-purple;
        color: $light-color;
    }

    &:disabled {
        background: $color-light-gray;
        color: $gray-500;

        &:hover {
            background: $color-light-gray;
            color: $gray-500;
            cursor: not-allowed;
        }
    }
}


