.select-assistant .my-select div {
    margin-bottom: 0;
}

.add-new {
    height: 36px;
    background: linear-gradient(146.18deg, #81b1eb 24.02%, #6c48ff 133.19%);
    color: #ffffff;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 0;

    @media screen and (max-width: 667px) {
        width: 100%;
      }
}

.add-new span {
    position: absolute;
    top: 1px;
    right: 1px;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1;
    color: #777EF5;
    background: #ffffff;
    border-radius: 6px;
    padding: 3px;
}

.Toastify {
    font-size: 1.3rem;
}

.css-fz3o4l-control {
    margin-bottom: 1.5em;
}
