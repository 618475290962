@import "../../../../../../assets/scss/variable";

.button {
  margin: 1rem 0;
}

.editSheet {
  margin-bottom: 4rem;
  display: block;

  a {
    font-size: 1.5rem;
    color: $accent-color;

    &:hover {
      color: #41BDFF;
    }
  }
}
