@import '../../assets/scss/variable';

.modal {
    width: calc(100vw - 3rem);
    max-width: 500px;
    padding: 2.5rem;
    border-radius: 1rem;
}

.modalSubtitle,
.modalTitle {
    margin-bottom: 2rem;
    text-align: center;

    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.3;
}

.modalTitle + .modalSubtitle {
    margin-top: -0.75rem;
}

.modalSubtitle {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.3;
}

.modalInput {
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 0.8rem 0 0.8rem 1.25rem;
    border: 0.06rem solid $gray-medium;
    border-radius: 0.5rem;
    background-color: $light-color;
    font-weight: 500;
    font-size: 1.25rem;
}

.modalLabel {
    display: block;
    margin-bottom: 0.4rem;
    text-align: left;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
}

.modalDesc {
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 500;
    font-size: 1.2rem;
}

.modalBtn {
    @include flex-center(row, center, center);
    gap: 0.5rem;
    width: 18.5rem;
    margin: 0 auto;
    padding: 0.8rem;
    border: 1px solid $purple;
    border-radius: 1.25rem;
    background-color: $light-color;
    color: $gray-500;

    font-weight: 600;
    font-size: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.2rem;

    &:not(:disabled):hover {
        border: 1px solid transparent;
        background: $gradient-blue-to-purple-angled;
        color: $light-color;
    }
}

.saveBtn {
    border: 1px solid $purple;
    color: $purple;

    &:not(:disabled):hover {
        background: $gradient-blue-to-purple-angled;
        color: $light-color;
    }
}

.closeBtn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-weight: 700;
    font-size: 2rem;
    cursor: pointer;
}

.row,
.btns {
    display: grid;

    gap: 2rem;

    @media screen and (min-width: 667px) {
        display: flex;
        justify-content: center;
    }
}

.row + .row {
    margin-top: 2rem;
}

.modal .css-fz3o4l-control {
    background: $light-color;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
}

.wrapperModalInput {
    position: relative;
}

.modalInputStrLen {
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0.25rem;
    font-size: 1rem;
}

.modalBtn.btnDanger {
    border: 1px solid $danger;
    background: $danger;
    color: $light-color;

    &:hover {
        background: $danger;
    }
}

.btnShadow {
    box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
}
