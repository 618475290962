@import '../../../../../assets/scss/variable';

.accountChats {
    display: flex;

/*     overflow: hidden;
    flex-basis: 35%; */
    flex-direction: column;
    box-sizing: border-box;
    max-width: 600px;
    height: calc(100vh - 3.4rem);
    margin-right: 0.5rem;
    padding: 1.7rem;
    border-radius: 2.5rem;
    background-color: $light-color;
    transition: all 0.8s;
    min-width: 41rem;

    @media (max-width: 1023px) {
        width: auto;
        min-width: 200px;
    }

    @media (max-width: 666px) {
        position: absolute;
        z-index: 999;
        top: 55px;
        width: 100%;
        max-width: 100vw;
        height: calc(100vh - 70px);
        transform: translateX(-110%);
    }

    &.rotated {
        transform: translateX(0%);
    }
}

.chatHeader {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .searchBarContainer {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
        width: 100%;
        min-width: 0;

        gap: 1rem;
    }

    .filtersWrapper {

        max-height: 500px;
        opacity: 1;
        transition: max-height 0.7s ease, opacity 0.7s ease;

        &.hidden {
            max-height: 0;
            opacity: 0;
            display: none;
        }
    }

    .filtersToggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: fit-content;
        height: 3.25rem;
        padding: 5px;
        border: none;
        border-radius: 1.25rem;
        background-color: $gray;
        cursor: pointer;

        &.filterOpened {
            border: 1px solid #7d8aab;
        }

        @media screen and (max-width: 1023px) {
            width: auto;
            height: auto;
        }

        img {
            max-width: 2rem;
            max-height: 2rem;

            @media screen and (max-width: 1023px) {
                max-width: 2.5rem;
                max-height: 2.5rem;
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        gap: 7px;

        &:last-child {
            margin-bottom: 0rem;
        }

        > span {
            margin-bottom: 1rem;
        }

        .cleanAllBtn {
            height: 20px;
            margin-bottom: 1rem;
            color: $purple;
            text-align: right;

            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.4;

            text-decoration-line: underline;

            &:disabled {
                color: $gray-500;
            }
        }
    }
}

.filterItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin-bottom: 1.5rem;
    padding: 0 0 0 10px;
    border: 1px solid #7d8aab;
    border-radius: 1.3rem;
    color: $gray-500;

    @media screen and (max-width: 1023px) {
        height: 3.5rem;
    }

    &.assistantItem {
        position: relative;
    }

    &.selectedFilters {
        color: $purple;
    }

    svg {
        height: 1.75rem;
    }
}

.chatList {
    position: relative;
    overflow: auto;
    box-sizing: border-box;
}

.chatListItems {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    gap: 0.7rem;
}

.operator {
    position: relative;
    display: inline-block;
}

.unread {
    position: absolute;
    top: 0;
    right: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 100%;
    background: $gradient-light-blue-purple;

    .quantity {
        color: $light-color;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 1;
    }
}

.more {
    width: 60%;
    margin: 1rem auto 0;
    padding: 3px 10px;
    border-radius: 8px;
    background: $purple;
    color: $light-color;

    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
    cursor: pointer;
    transition: all 0.8s;

    margin-block-end: 1.5rem;
    margin-block-start: 1rem;
    padding-block: 0.4rem;

    &:hover {
        transform: scale(0.96);
    }

    &:active {
        transform: scale(0.86);
    }
}

.toggle {
    position: relative;
    z-index: 9999;
    height: calc(100vh - 70px);

    @media (min-width: 667px) {
        display: none;
    }

    .btn {
        position: absolute;
        z-index: 99;
        top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: $primary-color;
        transition: 0.8s;
        transform: translate(-15%);

        @media screen and (max-width: 450px) {
            top: 110px;
        }

        &.rotated {
            transform: rotate(180deg) translate(5%);
        }

        & svg {
            pointer-events: none;
        }
    }
}

.loaderContainer {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
}

.preloadMessageList {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;

    p {
        padding: 2.5rem;

        font-weight: normal;
        font-size: 1.75rem;

        @media (max-width: 666px) {
            padding: 1.5rem;
        }
    }
}

.emptyData {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;

    padding-block-start: 12px;
}

.customDropdown {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border: 1px solid $gray-500;
    border-radius: 6px;
    transform: translateY(-50%);

    .menuIcon {
        font-size: 1.5rem;
        cursor: pointer;
    }

    .dropdownMenu {
        position: absolute;
        z-index: 1000;
        top: 3rem;
        right: 0;
        width: 20rem;
        border: 1px solid #D8D8D8;
        border-radius: 0.6rem;
        background: #ece6ff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);

        &::before {
            content: '';
            position: absolute;
            top: -9px;
            right: 2px;
            width: 0;
            height: 0;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #ece6ff;
            border-left: 10px solid transparent;
            filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.3));
        }

        .dropdownItem {
            //color: $purple;
            display: flex;
            align-items: center;
            padding: 0.75rem 1rem;
            color: $gray-500;

            font-weight: 600;
            font-size: 1.1rem;
            cursor: pointer;

            gap: 0.5rem;
        }
    }
}

.funnelStatusSelect {
    //position: relative;
    margin: 1.5rem;

    h3 {
        margin-bottom: 1.25rem;
    }
}

