@import "../../assets/scss/variable";

.modalOpen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
}

.modalHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    // width: 0.75rem;
    // height: 0.75rem;
    cursor: pointer;
  }
}

.modalContent {
  width: 70rem;
  background-color: $light-color;
  padding: 2.5rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1 {

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: 0;
  }

  p {

    font-size: 1.35rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    margin-top: 1rem;
    color: rgb(78, 76, 76);
  }
}
