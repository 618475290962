@import '../../../../assets/scss/variable.scss';

.followUpItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px 28px;
    border: 1px solid #1e1e1e;
    border-radius: 0.6rem;

    gap: 4rem;

    @media screen and (max-width: 769px) {
        padding: 10px 12px;
        gap: 1rem;
    }
}

.followUpDetails {
    display: grid;
    align-items: center;
    width: 80%;

    grid-template-columns: 35% 15% 15% 15% auto;

    & p {
        color: $gray-500;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.43;

        @media screen and (max-width: 667px) {
            word-break: break-word;
            font-size: 0.9rem;
        }

        &.followUpName,
        &.statusName {
            margin-right: 1.5rem;

            @media screen and (max-width: 667px) {
                margin-right: 0.5rem;
            }
        }

        &.executed {
            @media screen and (max-width: 667px) {
                text-align: center;
            }
        }
    }
}

.followUpActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    gap: 20px;

    @media screen and (max-width: 667px) {
      width: auto;
      gap: 10px;
  }
}

.actionsButtons {
    width: 24px;
    height: 24px;

    @media screen and (max-width: 667px) {
      width: 16px;
      height: 16px;
  }
}

.publishButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    min-width: 6rem;
    height: 2.6rem;
    padding: 10px;
    border-radius: 0.5rem;
    background: $gradient-blue-to-purple-angled;
    color: $light-color;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2;
    transition: all 250ms;

    @media screen and (max-width: 1920px) {
        width: calc(100% / 2);
    }

    @media screen and (max-width: 769px) {
        min-width: 6rem;
        padding: 6px;
        font-size: 0.7rem;
    }
}


