@import "../../../../../../../assets/scss/variable";

.btn {
  & svg {
    transition: 0.8s;
    &:hover {
      filter: drop-shadow(0 2px 4px #00000080);
      transform: scale(0.93);
    }
  }
}

.container {
  position: absolute;
  right: 30px;
  top: 70px;
  width: 245px;
  height: auto;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 26px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-weight: 500;
  font-size: 1.5rem;
  color: $blue-800;
  background-color: $gray;
  border: 1px solid $gray-300;
  box-shadow: 0 4px 10px #6666660d;
  & .actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .action {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
      font-size: 1.3rem;
      p {
        transition: 0.8s;
      }
      &:hover {
        p {
          transform: scale(0.93);
          filter: drop-shadow(0 1px 1px #00000033);
        }
      }
    }
  }
}
