@import "../../../../assets/scss/variable";

.enableBotText {
  margin-left: 0.5rem;
  font-size: 1.5rem;

  font-weight: 500;
}

.enableBot {
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  background-color: $light-color;
  width: 100%;
  border-radius: 0.8rem;
}

.enableBotWrapper {
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: $light-color;
  width: 100%;
  border-radius: 0.8rem;
}
