.container {
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.number {
  color: #777ef5;
}
