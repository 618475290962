@import '../../../assets/scss/variable';

.mobileHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.25rem;

    @media (min-width: 667px) {
        display: none;
    }
}

.burgerMenu {
    z-index: 1;
    width: 1.25rem;
    height: 0.6rem;
}

.logoContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    img {
        width: 16rem;
        height: auto;
    }
}

.sidebar {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    height: 100vh;
    background-color: $light-color;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.navigationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .navigation:last-child {
        padding: 0;
    }

    .navigationActive:last-child {
        padding: 0.5rem;
    }
}

.navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 1.25rem 0.8rem 1.25rem;
    color: $gray-500;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 0.7;
    cursor: pointer;

    @media screen and (max-width: 360px) {
        margin: 0 1.25rem 0.5rem 1.25rem;
        font-size: 1.3rem;
    }

    > a {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

    svg,
    > img {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.75rem 1.8rem 0.75rem 1.4rem;

        @media screen and (max-width: 360px) {
            width: 2rem;
            height: 2rem;
        }
    }

    &:hover {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        background: $gradient-light-blue-purple;
        color: $light-color;

        path {
            fill: none;
        }
    }
}

.nonActive {
    filter: blur(1px);
    pointer-events: none;
}

.logo {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 1.25rem 0 2.2rem 0;
    font-family: $family_secondary;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 1.5rem;

    @media screen and (min-width: 667px) {
        font-size: 1.3rem;
    }

    svg {
        width: 2.6rem;
        height: 2.6rem;

        @media screen and (min-width: 667px) {
            width: 2.2rem;
            height: 2.2rem;
            margin: 0 1.25rem 0 1.56rem;
        }
    }
}

.userCard {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;

    word-wrap: break-word;

    img {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 1rem;
        border-radius: 10px;
    }

    svg {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 0.5rem;
        border-radius: 50%;
    }

    &__userInfo {
        display: flex;
        overflow: hidden;
        align-items: center;
        flex-direction: row;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__name {
        overflow: hidden;
        margin-bottom: 0.3rem;
        color: $gray-500;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.2;
    }

    &__email {
        overflow: hidden;
        color: $gray-500;
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0;

        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1;
    }

    svg {
        width: 0.9rem;
        height: 1rem;
        margin-left: 0.6rem;
        cursor: pointer;
    }
}

.sidebarBackdrop {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);

    backdrop-filter: blur(5px);
}

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.8rem;
    margin-bottom: 2.3rem;

    img {
        width: 17rem;
        height: 3.1rem;
        margin-left: 2.6rem;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.2rem;
    }
}

.support {
    margin-top: 3rem;
}

.userIcon {
    svg {
        width: 2.25rem;
        height: 2.25rem;
        margin-left: 0;
    }
}

.textInfo {
    overflow: hidden;
    width: 21rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.navigationActive {
    padding: 0.25rem;
    border-radius: 1.2rem;
    background: $gradient-blue-to-purple-angled;
    color: $light-color;

    path {
        fill: none;
        stroke: $light-color;
    }
}

.profile {
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
    height: 3rem;
    margin: 1.9rem 0.5rem 3rem 1rem;
    padding-right: 0.7rem;
    padding-left: 1.5rem;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &.socials {
        height: auto;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }
}




