@import '../../../../../assets/scss/variable';

.wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 1023px) {
        width: 100%;
    }
}

.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 460px;
    gap: 10px;
}

.accountItem {
    height: fit-content;
    padding: 8px 16px;
    border-radius: 24px;
    background: $light-color;
    color: $purple;

    font-weight: 500;
    font-size: 1.7rem;
    line-height: 1;

    @media screen and (max-width: 1023px) {
        padding: 5px 15px;
        font-size: 1.25rem;
        line-height: 1.5;
    }

    &.activeAccount {
        margin-bottom: 24px;
        background: $purple;
        color: $light-color;
    }
}

.accountInfo,
.accountDetails {
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.telegramConnection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 24px 0;


    gap: 24px;

    label {
        padding-bottom: 8px;
        color: $gray-500;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.4;
    }

    input {
        width: 460px;
        margin-top: 0.5rem;

        @media screen and (max-width: 768px) {
            width: 100%;
            font-size: 1.15rem;
        }
    }

    .phoneInput {
        width: 460px;
        padding: 1rem 2.5rem;
        border: 0.1rem solid #7c8cf0;
        border-radius: 0.5rem;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1rem;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    input.PhoneInputInput {
        margin-top: 0;
        outline: none;
    }

    .connectButton,
    .deleteButton {
        width: 460px;
        text-transform: none;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}
