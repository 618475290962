@import '../../../../assets/scss/variable.scss';

.formContainer {
    margin: 4rem 0 1.5rem;
}

.operatorsContent {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1.5rem;
  }

.operatorsInfo {
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 667px) {
        flex-direction: column;
    }

    .operatorsListWrapper {
        display: flex;
        overflow: auto;
        flex-direction: column;
        width: 40%;
        //height: 85vh;
        margin-top: 1rem;

        @media screen and (max-width: 667px) {
            width: 100%;
            height: auto;
        }
    }

    .operatorsList {
        margin-top: 1.5rem;
        padding: 0;
        list-style: none;


    }

    .operatorItem {
        @include flex-center(row, flex-start, center);
        margin-bottom: 1.5rem;
        padding: 1rem;
        cursor: pointer;

        gap: 1.2rem;

        &.selected,
        &:hover {
            background-color: $gray;
            border-radius: 1.5rem;
        }

        img {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 1rem;
        }

        .operatorInfo {
            display: flex;
            flex-direction: column;

            gap: 0.5rem;

            &:hover .operatorRole {
                background: $light-color;
            }

            .operatorName {
                color: $gray-500;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.2;
            }

            .operatorRole {
                width: fit-content;
                padding: 2px 6px;
                border-radius: 0.5rem;
                background: $gray;
                color: #7d8aab;
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.4;
            }
        }

        &.selected {
            .operatorInfo {
                .operatorRole {
                    background: $light-color;
                }
            }
        }
    }

    .selectedOperator {
        flex: 1;
        margin-top: 3.25rem;
        padding: 2rem;
        border: 1px solid $gray-300;
        border-radius: 1.5rem;

        @media screen and (max-width: 667px) {
            margin-top: 0rem;
        }

        button {
            @include flex-center(row, flex-start, center);
            box-sizing: border-box;
            height: auto;
            padding: 0.7rem 2rem;
            border-radius: 1.5rem;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.2;
        }

        .borderBtn {
            border: 1px solid $purple;
            color: $purple;
        }

        .editButton {
            @include flex-center(row, flex-start, center);
            border: 1px solid $purple;
            color: $purple;

            gap: 0.5rem;

            .icon {
                margin-left: 1rem;
                font-size: 2rem;
            }
        }

        .btnDelete {
            margin: 0 0 0 auto;
            margin-top: 1rem;
            background: $danger;
            color: $light-color;

            @media screen and (min-width: 667px) {
                width: max-content;
            }

            &:hover {
                background: linear-gradient(to bottom, #fcb0b0, #f15f5f);
            }

            .icon {
                margin-left: 1rem;
                font-size: 2rem;
            }
        }

        .detailAvatarBlock {
            @include flex-center(row, space-between, center);
            padding-top: 2.5rem;

            .operatorItem {
                padding: 1rem;
            }

            img {
                width: 5rem;
                height: 5rem;
            }
        }

        .btnWrapper {
            @include flex-center(row, flex-end, flex-start);
            margin-top: 2.5rem;

            gap: 1rem;

            button {
                justify-content: center;
                width: 14rem;
            }
        }

        .operatorMainInfo {
            display: flex;
            flex-direction: column;
            padding: 1rem;

            .infoWrapper {
                display: flex;
                flex-direction: column;
                margin-bottom: 3.5rem;

                gap: 1rem;

                label {
                    color: #7d8aab;

                    font-weight: 400;
                    font-size: 1.3rem;
                    line-height: 1.375;
                }

                .info {
                    color: $gray-500;

                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 1.375;
                }
            }
        }
    }
}


.loaderContainer {
    display: flex;
    flex: 1;
    height: 80vh;
    @include flex-center(row, center, center);
}