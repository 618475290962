@import '../../assets/scss/variable.scss';

.collapsibleMessage {
  position: relative;
  display: block;

  .contentWrapper {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
  }

  .hiddenTruncatedContent {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.4;
    word-break: break-word;
    background: $gray;
    padding: 0.5rem;
    margin: 0;
  }

  .toggleButton {
    margin: 0.5rem 0;
    border: none;
    background: none;
    color: $accent-color;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $purple;
    }
  }
}