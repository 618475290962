.container {
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.cardsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  flex-grow: 1;
  gap: 1.5rem;
}
