.modalToggle {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin: 20px 0;
}

.toggleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}