.container {
  margin: 1.5rem;
}

.divider {
  background-color: #e2daff;
  width: 100%;
  height: 1px;
  margin: 2.5rem 0;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.footerText {
  display: block;
  margin-top: 1rem;
}
