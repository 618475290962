@import '../../assets/scss/variable';

.container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1.3rem 1.2rem;
    border-radius: 0;
    cursor: pointer;
    gap: 1rem;

    @media screen and (min-width: 667px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 6.5rem;
        padding: 1.1rem 1.2rem;
        border-radius: 0;
    }

    &.nonActive {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }

    .multipro {
        background: #e2daff!important;
        color: $purple;
    }
}

.container:hover {
    border-radius: 1.2rem;
    background: #F0F2F4;
}

.title {
    margin-left: 2.5rem;
    letter-spacing: 0;

    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.6rem;

    @media screen and (min-width: 667px) {
        margin-left: 2.5rem;
        font-size: 1.5rem;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    background-color: #3588e9;
    color: $light-color;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.2;

    @media screen and (min-width: 667px) {
        border-radius: 2.5rem;
        font-size: 1.3rem;
        max-width: 11rem;
    }

    span {
        font-size: 1.05rem;
        font-weight: 500;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media screen and (min-width: 667px) {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    img,
    svg {
        width: 4.3rem;
        height: 4.3rem;

        @media screen and (min-width: 667px) {
            width: 3.1rem;
            height: 3.1rem;
        }
    }
}

.disabledContainer {
    cursor: default;
}

.disabledContainer:hover {
    background-color: $light-color;
}

.disabledButton {
    background-color: #999999;
    cursor: default;
}

.disabledContainer {
    cursor: default;
}

.disabledTitle {
    color: #999999;
}

