@import "../../../../assets/scss/variable";

.loader {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.connectionBot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  border-radius: 0.8rem;
  background-color: $light-color;

  a {
    text-decoration: none;
  }

  &.noAccounts {
    align-items: center;
    justify-content: center;

    gap: 1.5rem;
  }
}

.saveChanges {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  background-color: transparent;

  @media screen and (min-width: 667px) {
    flex-direction: row;
    justify-content: flex-end;
    height: 4rem;
    margin-top: 1.5rem;
    padding: 0.5rem;
    border-radius: 0.8rem;
    background-color: $light-color;
  }
}

.saveOptimize {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(to bottom, $light-color, #dae3fe);
  box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
  color: $gray-500;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1rem;

  @media screen and (min-width: 667px) {
    width: 22.5rem;
    font-weight: 700;
    font-size: 1.25rem;
  }

  &:hover {
    background: $gradient-blue-purple;
    color: $light-color;
  }

  &:disabled {
    background: $color-light-gray;
    color: $gray-500;
    cursor: not-allowed;
  }
}

.connectionBodyHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1.5rem;

    button {
      min-width: 10rem;
    }
  }
}

.countryOptionsWrapper {
  display: flex;
  flex-direction: row;

  gap: 1rem;
}

.connectionBotText {
  margin-bottom: 1rem;

  font-weight: 500;
  font-size: 1.5rem;

  a {
    color: $purple;

    font-weight: 500;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
}

.titleWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;

  .saveOptimize {
    width: auto;
    padding: 1rem 3rem;
  }
}

.accountTabs {
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
}

.accountTabButton {
  overflow: hidden;
  max-width: 9rem;
  padding: 0.7rem 1.5rem;
  border-radius: 1.5rem;
  background-color: $light-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;

  &.active {
    background-color: #e1dafb;
    cursor: none;
    pointer-events: none;
  }

  &:hover {
    background-color: #e1dafb;
  }
}
