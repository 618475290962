@import "../../assets/scss/variable";

.top {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 667px) {
    align-items: center;
    margin: 2.5rem;
    grid-template-columns: 40.25rem auto;
  }
}



.container {
  margin: 1rem;
  @media screen and (min-width: 667px) {
    margin: 0;
  }
}

.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  @media screen and (min-width: 667px) {
    margin: 2.5rem 2.5rem 0 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #ccd5ed;

  @media screen and (min-width: 667px) {
    background-color: $gray-300;
    margin-left: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }
}

.description {

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.9rem;
  letter-spacing: 0;
  margin-top: 0.5rem;
  @media screen and (min-width: 667px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.textContainer {
  display: flex;
  margin: 1rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (min-width: 667px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.mb-1_5 {
  margin-bottom: 1.5rem;
}

.integrations {
  background-color: $light-color;
  height: 100%;
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;


  @media screen and (min-width: 667px) {
    background-color: $light-color;
    padding: 1rem 0 2rem 0;
    width: 40.25rem;
    border-radius: 0;
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    align-items: flex-start;
    flex-direction: column;
  }


}

.title {
  padding: 0 2.5rem;
  margin-bottom: 0.9rem;
  @media screen and (min-width: 667px) {
    margin-bottom: 0;
  }
}

.buttonModal {
  text-transform: uppercase;
  background: $color-light-gray;
  color: $gray-500;
  width: 100%;
  border-radius: 0.6rem;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0;
  padding: 0.8rem;
  @media screen and (min-width: 667px) {
    border-radius: 0.5rem;
    padding: 0.7rem;
  }
}

.buttonModal:hover {
  background: $gradient-blue-purple;
  color: $light-color;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 13.2rem;
  border-radius: 1.25rem;
  background-color: $light-color;
  padding: 2rem;
  @media screen and (min-width: 667px) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 25rem;
    height: 10rem;
    border-radius: 1.25rem;
    padding: 1.25rem;
  }
}

.textModal {

  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: 0;
  @media screen and (min-width: 667px) {
    font-size: 1.25rem;
  }
}

.modalContainer {
  margin: auto;
}

.emptyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ccd5ed;
  padding: 7rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  img {
    width: 18rem;
    height: 13.5rem;
    margin-bottom: 1.3rem;
  }

  @media screen and (min-width: 667px) {
    border-radius: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: -10px 0px 10px 0px rgb(227, 230, 238);

    img {
      width: 16.7rem;
      height: 12.5rem;
      margin-bottom: 1.25rem;
    }
  }
}

.channel {
  width: 100%;
  margin-top: 0;
  overflow: auto;
  max-height: 85vh;
  position: relative;
  right: 1rem;
  padding: 0.75rem 2.5rem;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #F0F2F4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #C6B9FA;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #777ef5;
    cursor: pointer;
  }

}

.channel.active {
  filter: blur(3px);
  height: 100%;
  pointer-events: none;
}

.title.active {
  filter: blur(3px);
  height: 100%;
  pointer-events: none;
}

.tableContainer {
  flex-direction: column;
  width: 100%;
}

.table {
  display: table;
  margin-top: 2.5rem;
}

.share {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

.connection {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

.lastItem {

  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.7rem;
  letter-spacing: 0;
}

.tableItem {

  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: 0;
}

table {

  border-collapse: collapse;
  height: 100%;
  width: 100%;
  padding: 2.5rem;
}

th {
  text-align: left;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

td,
th {
  height: 50px;
  padding-left: 2.5rem;

  svg {
    height: 1.25rem;
    width: 1.1rem;
  }
}

.button {
  background-color: #3588e9;
  width: 8.1rem;
  border-radius: 1.25rem;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0;
  padding: 0.1rem;
  color: $light-color;
  @media screen and (min-width: 667px) {
    width: 5.6rem;
    border-radius: 2.5rem;
    font-size: 0.9rem;
    padding: 0.1rem;
  }
}

.tableHeader {
  border-bottom: 0.1rem solid $gray;
}

.mobileConnection {
  margin: 2.5rem 2rem 2rem;
}

.connectItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;

  font-weight: 600;
  margin: 2.4rem 0;

  svg {
    height: 1.25rem;
    width: 1.1rem;
  }
}

.lastItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;

  font-weight: 800;

  svg {
    height: 1.25rem;
    width: 1.1rem;
  }

  margin-top: 2.4rem;
}

.connectionChanel {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 0.6rem;
  }
}

.shareChanel {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 1.3rem;
  }
}

.connectionTab {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.5rem;
  gap: 1.5rem;

  @media screen and (min-width: 667px) {
    box-shadow: -10px 0px 10px 0px rgb(239, 239, 243);
  }
}

.enableBot {
  padding: 1.25rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  background-color: $light-color;
  width: 100%;
  border-radius: 0.8rem;
}

.enableBotText {
  margin-left: 0.5rem;
  font-size: 1.5rem;

  font-weight: 500;
}

.connectionBot {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background-color: $light-color;
  width: 100%;
  border-radius: 0.8rem;
}

.connectionBotText {
  font-size: 1.5rem;

  font-weight: 500;

  a {
    color: $purple;
    font-size: 1.5rem;

    font-weight: 500;
  }
}

.token {
  margin-top: 1.5rem;
  font-size: 1.7rem;

  font-weight: 600;
}

.saveChanges {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $purple;
  height: 4rem;
  margin-top: 1.5rem;
  border-radius: 0.8rem;
}

.saveChangesText {
  font-size: 1.25rem;

  font-weight: 600;
  color: $light-color;
}

.saveChangesButton {
  margin-left: 1rem;
  display: flex;
  font-size: 1rem;

  font-weight: 600;
  color: $purple;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10rem;
  background-color: $light-color;
  border-radius: 0.25rem;
}

.saveChangesButton:hover {
  background-color: #dfd5ff;
}

.emptyChannel {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.loaderContainer {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
