@import "../../../../assets/scss/variable";

.container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  background-color: $gray-300;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.description {
  margin: 0.75rem 0 1.5rem;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.375rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  margin-top: 0;
  width: 100%;
}

.submitButton {
  height: 100%;
}

.error {
  margin-top: 0.5rem;
}
