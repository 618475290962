@import '../../../assets/scss/variable.scss';

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    gap: 1.5rem;
}

.wInput {
    width: 100%;
}

.iLabel {
    display: block;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 1.2rem;

    @media screen and (max-width: 1024px) {
        font-size: 1.5rem;
    }
}

.formInput {
    width: 100%;
}

.wForm {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    opacity: 0;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    gap: 0.75rem;
}

.selectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 0.5rem;
    background: $gradient-blue-to-purple-angled;
    color: $light-color;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    @media screen and (max-width: 1024px) {
        font-size: 1.4rem;
    }
}
