@import "../../../assets/scss/variable.scss";

.successBtn {
    text-transform: uppercase;
    background: $gradient-blue-to-purple-angled;
    width: 12.5rem;
    height: 3rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.7rem;
    padding: 0.8rem;
    color: $light-color;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    transition: all 250ms;
    margin: 0 auto;
    margin-top: 1.5rem;

    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.45);
    }

}