@import "../../../../assets/scss/variable";

.container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  background-color: $gray-300;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.description {
  margin: 0.75rem 0 1.5rem;
}

.inputTitle {
  font-size: 1rem;
  color: #1e1e1e;
  margin-bottom: 0.75rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  width: 100%;
}

.input {
  margin-top: 0;
  width: 100%;
}

.submitButton {
  width: fit-content;
}

.errorText {
  margin-top: 0.5rem;
  color: $danger;
}
