@import '../../../../assets/scss/variable';

.newAccount {
    height: fit-content;
    margin-bottom: 10px;
    padding: 8px 16px;
    border-radius: 24px;
    background: $purple;
    color: $light-color;

    font-weight: 500;
    font-size: 1.7rem;
    line-height: 1;

    @media screen and (max-width: 1023px) {
        margin-bottom: 5px;
        padding: 5px 15px;
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addAccount {
    padding: 10px 32px;
    border: 2px solid $purple;
    border-radius: 8px;
    color: $purple;

    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;

    @media screen and (max-width: 1023px) {
        padding: 7px 15px;
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.subtitle {
    margin-top: 1.5rem;
}

.connectionBot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 0.8rem;
    background-color: $light-color;

    a {
        text-decoration: none;
    }
}

.wrapperConnectedAccounts {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    gap: 20px;
}

.loaderContainer {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    height: 100vh;
}

.enableBot {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 4.5rem;
    padding: 1.25rem;
    border-radius: 0.8rem;
}
