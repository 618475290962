@import "../../../../assets/scss/variable";

.replayDelayInputWrapper {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  display: flex;
  gap: 12px;
  align-items: center;
  padding: 6px;
}

.replayDelayInput {
  margin-top: 0;
  text-align: center;
  width: 100px;

  &:disabled {
    cursor: not-allowed;
  }
}

.replayDelayInputSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.replayDelayInputLabel {
  font-size: 1.5rem;
  font-family: "Montserrat", serif;
  font-weight: 500;
}
.replayDelayInputControlWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

$btn-size: 3em;

.replayDelayInputControlBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $btn-size;
  height: $btn-size;
  min-width: $btn-size;
  box-shadow: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.replayDelayInputSaveBtn {
  flex-grow: 1;
  padding: 12px;
  height: $btn-size;
  min-width: 10rem;
  box-shadow: none;

  &:disabled {
    cursor: not-allowed;
  }
}
