@import "../../../assets/scss/variable.scss";


.form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.wInput {
  width: 100%;
}

.iLabel {
  font-weight: 700;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.formPrice {
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 400;
}

.formInput {
  width: 100%;
}

.proceedToPaymentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
  background-color: $light-color;
  color: $gray-500;
  border-radius: 0.5rem;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
  @media screen and (min-width: 667px) {
    font-weight: 700;
  }

  &:hover {
    background: $gradient-blue-purple;
    color: $light-color;
  }

  &:disabled {
    background: $color-light-gray;
    color: $gray-500;
    cursor: not-allowed;
  }
}

.wForm {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  margin: -1px;
}

.buttonGroup {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  margin-top: 20px;
}

.selectButton {
  font-size: 1.25rem;
  padding: 10px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid $color-light-gray;
  background-color: $light-color;
  color: $gray-500;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &.active {
    background: $gradient-blue-to-purple-angled;
    color: $light-color;
  }

  &:hover {
    background: $gradient-blue-to-purple-angled;
    color: $light-color;
  }
}
