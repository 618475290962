@import "../../assets/scss/variable";

.chat {
  background-color: #e1dafb;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.userChatHeader {
  height: 100%;
}

.clientInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: #e1dafb;

  img {
    border-radius: 50%;
    height: 5.8rem;
    width: 5.8rem;
    margin-right: 0.6rem;
  }
}

.clientData {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.6rem;
  }
}

.clientName {

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lineText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  @media screen and (min-width: 667px) {
    position: relative;
  }
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.1rem;
  background-color: $light-color;
}

.text {
  position: relative;
  z-index: 1;
  color: #3588e9;
  background-color: #e1dafb;
  padding: 0 0.6rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
}

.chatList {
  background-color: #e1dafb;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 1rem;
}

.message {
  position: relative;
  white-space: pre-wrap;
  word-break: break-word;

  max-width: 80%;
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  @media screen and (min-width: 667px) {
    white-space: pre-wrap;
    word-break: break-word;

    max-width: 48%;
    margin: 0.3rem 0;
    padding: 0.5rem;
    border-radius: 0.8rem;
    font-size: 0.9rem;
  }
}

.user {
  align-self: flex-end;
  background-color: #0b93f6;
  padding: 1.25rem 1rem;
  font-size: 1.25rem;
  border-bottom-right-radius: 0;
  color: $light-color;
}

.assistant {
  border-bottom-left-radius: 0;
  align-self: flex-start;
  padding: 1.25rem 1rem;
  font-size: 1.25rem;
  background-color: #e5e5ea;
}

.messageDate {

  color: $gray-500;
  padding: 0;
  font-size: 1rem;
  margin: 0 0.5rem 0.5rem;
  font-weight: 600;
  background: none;
}

.headerContainer {
  background-color: #e1dafb;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  border-bottom: 0.1rem solid $light-color;
  z-index: 1;
}

.messageContainer {
  display: flex;
  flex-direction: column;
}

// Spec message
.containerTyping {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  padding: 1rem;
  padding-left: 0;
}
.botResponse {
  display: flex;
  align-items: center;

  font-size: 1.25rem;

  p {
    margin-left: 0.75rem;
  }

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.specMessageIcon {
  display: none;
}
.message.assistant > .specMessageIcon {
  display: none;
  position: absolute;
  bottom: -6px;
  right: -8px;
  cursor: pointer;
  color: #439929;
}
.assistant:hover.message:hover {
  .specMessageIcon {
    display: block;
  }
}
.specialMsgTextarea {
  border-radius: 8px;
  padding: 16px 28px 16px 16px;
  resize: vertical;
  border-top-left-radius: 0;
  font-size: 1.25rem;
  // background-color: #cde8b5;
}
.specialMsgBox {
  // outline: 1px solid tomato;

  margin: 2px 0px 4px 0px;
  position: relative;
}
.specMessageIconSave {
  position: absolute;
  top: 0;
  right: 0;
  color: #439929;
  cursor: pointer;
}

.specialMsg {
  border-radius: 8px;
  border-top-left-radius: 0;
  margin: 0 0 0.3rem 0;
  padding: 1.25rem 1rem;
  font-size: 1.25rem;
  background-color: #cde8b5;
}
