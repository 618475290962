.edgeButton {
  width: 148px;
  height: 44px;
  border-radius: 50px;
  background: #e3e6ee;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconWrapper {
  border-left: 2px solid #384157;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.edgeLabel {
  text-transform: capitalize;
}
