.my-row .my-select div {
  margin-bottom: 0;
}

.my-select .css-1p3m7a8-multiValue {
  background: #fff;
}

.popup-content {
  width: fit-content;
  padding: 0;
}
