@import '../../../../../assets/scss/variable';

.toggle {
    position: relative;
    height: 100vh;

    @media (max-width: 666px) {
        width: 0;
        height: 90vh;
    }

    .btn {
        position: absolute;
        z-index: 999;
        top: 26%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px;
        border-radius: 1.5rem;
        background: #e2daff;
        color: $light-color;
        transition: 0.8s;
        transform: translate(-70%);

        @media screen and (max-width: 1024px) {
            padding: 6px;
        }

        @media screen and (max-width: 666px) {
            top: 100px;
        }

        @media (max-width: 666px) {
            padding: 0;
            background: transparent;
            color: $primary-color;
            transform: translate(-120%);
        }

        &:hover {
            background: $purple;
            transform: scale(0.9) translate(-70%);

            @media (max-width: 666px) {
                z-index: 99999;
                background: transparent;
                transform: scale(0.9) translate(-110%);
            }
        }

        &.rotated {
            background: $purple;
            transform: rotate(180deg) translate(50%);

            @media (max-width: 666px) {
                z-index: 99999;
                top: 10%;
                background: none;
                transform: rotate(180deg) translate(120%);
            }

            &:hover {
                transform: scale(0.9) translate(-50%) rotate(180deg);

                @media (max-width: 666px) {
                    z-index: 99999;
                    top: 10%;
                    transform: scale(0.9) translate(-120%) rotate(180deg);
                }
            }
        }
    }
}

.container {
    display: flex;
    overflow-x: auto;
    flex-direction: column;
    width: 75rem;
    min-width: 210px;
    height: 100vh;
    transition: 0.8s;

    @media (max-width: 1024px) {
        min-width: auto;
    }

    @media (max-width: 666px) {
        position: absolute;
        z-index: 9999;
        top: 50px;
        width: 100vw;
        height: calc(100vh - 70px);

        inset-inline-end: 0;
    }

    &.hide {
        width: 0;
        min-width: 0;
        transform: translateX(110%);
        pointer-events: none;

        margin-inline-end: 0;
    }

    & .header {
        position: sticky;
        z-index: 1;
        top: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 2rem;
        padding: 1.7rem;
        border-top-left-radius: 2.5rem;
        border-top-right-radius: 2.5rem;
        background-color: $light-color;
        gap: 10px;

        @media (max-width: 1024px) {
            margin-left: 1.5rem;
        }

        & h2 {
            overflow: hidden;
            max-width: 350px;
            color: #1e1e1e;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 2rem;
        }

        & img {
            width: 2rem;
            height: 2rem;
            object-fit: contain;
        }
    }

    & .content {
        display: flex;

//flex: 1;
        flex-direction: column;
        height: calc(100vh - 8rem);
        margin-left: 2rem;
        padding: 0 1.7rem 1.7rem;
        border-bottom-right-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
        background-color: $light-color;

        gap: 3rem;
        padding-block: 2rem;
        @media (max-width: 1024px) {
            margin-left: 1.5rem;
        }
    }

    & .divider {
        width: 100%;
        border-bottom: 1px solid $light-color;
    }

    & .btn {
        & svg:hover {
            filter: drop-shadow(0 2px 4px $primary-color);
        }
    }

    & .icon {
        color: $blue-800;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    background-color: #e1dafb;
    font-size: 2.6rem;

    @media screen and (min-width: 667px) {
        max-width: 48%;
        font-size: 1.1rem;
    }
}
