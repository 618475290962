.chatsContainer {
    display: flex;
    overflow-x: hidden;
    align-items: flex-start;
    padding: 1.75rem 1.75rem 1.75rem 2.5rem;

    @media screen and (max-width: 768px) {
        padding: 1.5rem;
    }

    @media screen and (max-width: 450px) {
        padding: 0;
    }
}
