@import '../../../../../../assets/scss/variable.scss';

.filterItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 3rem;
    border-radius: 2rem;
    color: $gray-500;

    h4 {
        box-sizing: border-box;
        margin-right: 2px;
        margin-left: 2px;
        padding: 2px 8px;
        color: $gray-500;

        font-weight: 500;
        font-size: 1.3rem;
    }
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    .allOption {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 2px solid $purple-light;
        border-radius: 50%;
        font-weight: 600;
    }

    svg {
        width: 32px!important;
        height: 32px!important;

        @media screen and (max-width: 1023px) {
            width: 23px!important;
            height: 23px!important;
        }

        @media screen and (max-width: 450px) {
            width: 30px!important;
            height: 30px!important;
        }
    }
}

.menuList {
    position: relative;
    z-index: 9;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    background: $light-color;

    [class*='option'] {
        margin: 0;
        padding: 7px;;
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    gap: 1.5rem;

    .applyButton,
    .cleanAllButton {
        width: 100%;
        padding: 10px 20px;
        border-radius: 16px;

        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1;
        cursor: pointer;

        @media screen and (max-width: 1023px) {
            padding: 8px 16px;
        }
    }

    .applyButton {
        border: none;
        background: $gradient-blue-to-purple-angled;
        color: $light-color;

        &:disabled {
            background: $gray-300;
            cursor: not-allowed;
        }
    }

    .cleanAllButton {
        border: 1px solid $purple;
        background-color: $light-color;
        color: $purple;

        &:disabled {
            border: 1px solid $gray-300;
            color: $gray-300;
            cursor: not-allowed;
        }
    }
}

.selectedCount {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purple;

    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1;

    gap: 4px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 5px;
        border-radius: 4px;
        background: $purple-light;
        color: $light-color;

        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1;

        gap: 4px;
    }
}

.selected {
    padding: 5px;
    border: 1px solid $purple;
    border-radius: 10px;
}
