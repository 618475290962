.imageContainer {
  margin-top: 3px;
}

.messageImage {
  margin-top: 3px;
  max-height: 450px;
  max-width: 415px;
  object-fit: contain;
  background: #e5e5ea;
  border-radius: 5px;
}
