.input-box,
.calendarWrap {
  position: relative;
  display: inline-block;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  max-width: 100vw;
  top: calc(100% + 3px);
  overflow-y: auto;
}

.input {
  box-shadow: 0px 0.37rem 0.37rem 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.56rem;
  color: rgb(52, 58, 64);
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 2.14rem 3rem 1.4rem 3rem;
  width: 100%;
  @media screen and (min-width: 667px) {
    width: auto;
    padding: 2.14rem 5.98rem 1.4rem 6.44rem;
  }
  &:focus {
    outline: none;
  }
}

.calendarWrap {
  .icon_chevron_down,
  .icon-calendar {
    position: absolute;
    cursor: pointer;
    transform: translateY(-50%);
    width: 2.99rem;
    height: 2.99rem;
    background: transparent;
    transition: transform 0.3s ease-in-out;
    top: 50%;
    left: 2rem;
    &.active {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .icon_chevron_down {
    left: auto;
    right: 2rem;
    cursor: auto;
  }
}

.icon_chevron_down,
.icon-calendar {
  display: none;
  @media screen and (min-width: 667px) {
    display: block;
  }
}

.listOfSettings {
  background: #fff;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.listOfSettingsItem {
  background: #f5f5f5;
  font-size: 1.2rem;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 10px 2px;
  text-align: center;
  position: relative;
  &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  &::before {
    display: none;
  }
  &.active::after {
    content: "";
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 100%;
    height: 3px;
    background: #000;
    border-radius: 0.5rem;
  }
}
