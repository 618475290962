@import "../../assets/scss/variable";

.title {
  margin: 2.5rem;
}

.container {
  height: calc(100vh - 12.5rem);
  margin: 2.5rem;
  border-radius: 1.5rem;
  background-color: $gray-300;
  display: flex;
  justify-content: center;
  padding: 4.6rem 0;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  overflow-y: auto;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-500;
    border-radius: 1rem;
  }
}
