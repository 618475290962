@import "../../../assets/scss/variable.scss";

.dropdown {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.dropdownButton {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.9rem;
  border: 1px solid $gray-300;
  padding: 0.6rem 0.8rem;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.active {
    border: 1px solid $gray-500;
  }

  & span {
    transition: all 300ms ease-in-out;
    text-wrap: nowrap;
  }

  & svg {
    & path {
      transition: all 300ms ease-in-out;
    }
  }

  &:hover {
    border-color: $purple;

    & span {
      color: $purple;
    }

    & svg {
      & path {
        stroke: $purple;
      }
    }
  }
}

.iconWrapper {
  display: flex;
  gap: 0.6rem;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.placeholder {
  color: $gray-500;
  font-weight: 400;
  font-size: 1.1rem;
}

.menu {
  position: absolute;
  top: 110%;
  left: 0;
  width: fit-content;
  height: fit-content;
  background: $light-color;
  border: 1px solid #e4e4e7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 1.35rem !important;
  padding: 1rem !important;

  transition: all 300ms ease-in-out;
  transform: translateY(0.7rem);
  opacity: 0;
  z-index: -10;

  &.active {
    transform: translateY(0);
    opacity: 1;
    z-index: 10000;
  }
}

.dropdownIcon {
  transition: all 300ms ease-in-out;

  &.active {
    transform: rotate(-180deg);
  }
}

.full {
  min-width: 100%;
  min-height: 100%;
  width: fit-content;
  height: fit-content;
}
