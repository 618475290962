.container {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f2f4;
  padding: 14px 18px 14px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.nameContainer {
  display: flex;
  gap: 9px;
}

.actionsContainer {
  display: flex;
  gap: 10px;
}
