@import '../../../../../../../assets/scss/variable';

.container {
    display: flex;
    flex-direction: column;

    gap: 2rem;

    .title {
        color: $gray-500;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1;
    }

    .filterItemWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.85rem;
    }

    .filterItem {
        display: flex;
        align-items: center;
        width: 100%;
        height: 3.5rem;
        padding: 1rem;
        border: 1px solid #7d8aab;
        border-radius: 1rem;
        color: $gray-500;
        position: relative;

        @media screen and (max-width: 1023px) {
            height: 3.5rem;
        }

        &.assistantItem {
            position: relative;
        }

        &.selectedFilters {
            color: $purple;
        }

        svg {
            height: 1.75rem;
        }
    }
}

