@font-face {
   font-family: "Gilroy";
   src: url('../fonts/Gilroy-Medium.woff') format('woff'),
   url('../fonts/Gilroy-Medium.ttf') format('truetype');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Montserrat";
   src: url('../fonts/Montserrat-Medium.woff') format('woff'),
   url('../fonts/Montserrat-Medium.ttf') format('truetype');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Montserrat";
   src: url('../fonts/Montserrat-SemiBold.woff') format('woff'),
   url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Montserrat";
   src: url('../fonts/Montserrat-Bold.woff') format('woff'),
   url('../fonts/Montserrat-Bold.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Poppins";
   src: url("../fonts/Poppins-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}


