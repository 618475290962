@import '../../assets/scss/variable';

.searchWrapper {
    position: relative;
    width: 100%;

    .searchInput {
        position: relative;
        width: 100%;
        height: 3.25rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        padding: 12px 8px 12px 3.5rem;
        outline: none;
        border-radius: 1.25rem;
        background-color: $gray;
        color: $gray-500;
        letter-spacing: 0;

        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .searchIcon {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);

        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
