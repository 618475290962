@import "../../assets/scss/variable";

.contained {
  display: flex;
  align-items: center;
  justify-content: center;
  //text-transform: uppercase;
  background: $gradient-blue-to-purple-angled;
  min-width: 11.5rem;
  height: 3rem;
  border-radius: 0.5rem;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: 0;
  padding: 0.8rem;
  color: $light-color;
  box-shadow: 0px 4px 8px 0px #00000040;
  transition: all 250ms;

  @media screen and (min-width: 667px) {
    padding: 0.7rem;
    min-width: 12.5rem;
  }

  &.hide {
    color: $gray-500;
    background: linear-gradient(146.18deg, #d9d9db 24.02%, #dcd9e8 133.19%);
  }
  &.edit,
  &.light {
    color: $gray-500;
    background: $light-color;
  }

  .disabled {
    background: $color-light-gray;
    color: $gray-500;
    cursor: not-allowed;
  }

  &.selected {
    background: $gradient-blue-to-purple-angled;
  }

  &:hover {
    box-shadow: 0px 4px 8px 0px #00000070;
  }

  &.error {
    transition: none;
    box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
    background: $danger;
    color: $light-color;

    &:hover {
      background: linear-gradient(to bottom, #fcb0b0, #f15f5f);
    }
  }
}
