@import "../../../assets/scss/variable.scss";

.container {
  width: 100%;
  position: relative;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 0.5rem;

  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  background-color: $light-color;
  border: 0.06rem solid $gray-medium;
  margin: 0 0 0 0;
  transition: margin 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.openClocks {
  margin: 30px 0 30px 0;
}
.timePickerContent {
  border-radius: 0.5rem;
  position: absolute;
  gap: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease-in-out;
}

.scale {
  transform: translate(-50%, -50%) scale(1);
}

.clockBackground {
  border-radius: 0.5rem;
  background-color: #f1f2f4;
}

.clockShadow {
  display: flex;
  gap: 10px;
}

.button {
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  z-index: 0;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
}

.buttonClose {
  left: 15px;
  @media screen and (max-width: 667px) {
    left: 55px;
  }
}

.buttonSave {
  right: 15px;
  @media screen and (max-width: 667px) {
    right: 55px;
  }
}

.showButton {
  opacity: 1;
  z-index: 1;
}