@import '../../../../../../../assets/scss/variable';

.container {
    & .icon {
        color: $gray-500;
    }

    & .list {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-300;
        padding-bottom: 1rem;

        & .item {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            color: $gray-500;
            font-weight: 500;
            font-size: 1.35rem;
            line-height: 1.4;

            gap: 0.8rem;
            padding-block-end: 0.85rem;
            padding-block-start: 0.85rem;
        }
    }
}
