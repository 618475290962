@import '../../../../assets/scss/variable.scss';

.container {
  background-color: $light-color;
  height: 100%;
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow-y: auto;

  @media screen and (min-width: 667px) {
    background-color: $light-color;
    padding: 2.5rem 0 2.5rem 0;
    width: 40.25rem;
    border-radius: 1.25rem 0 0 1.25rem;
    align-items: flex-start;
  }
}

.integrationItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem;
  justify-content: space-between;
  border-bottom: 0.1rem solid $gray-300;

  @media screen and (min-width: 667px) {
    padding: 0.75rem 3.75rem 0.75rem 2.5rem;
    width: 100%;
    min-height: 7rem;
    border-radius: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  &:hover {
    background-color: #f5f5f5;
    border-radius: 0;
  }

  &.selected {
    background-color: #f6f6f6;
  }
}

.iconContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  svg,
  img {
    height: 4.3rem;
    width: 4.3rem;
    object-fit: contain;

    @media screen and (min-width: 667px) {
      height: 3.15rem;
      width: 3.15rem;
    }
  }
}

.title {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0;
  color: $primary-color;

  @media screen and (min-width: 667px) {
    font-size: 1.5rem;
  }
}

.integrationInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
