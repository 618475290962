.container {
  margin: 1rem;
  height: 100vh;
  overflow: hidden;

  @media (max-width: "666px") {
    height: calc(100vh - 70px);
    margin: 0;
  }
  @media screen and (min-width: 667px) {
    margin: 0;
  }
}
