@import '../../assets/scss/variable';

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 20px;

    img {
        width: 12.8rem;
        height: 11.6rem;

        @media screen and (min-width: 667px) {
            width: 29.3rem;
            height: 20.4rem;
        }
    }

    .description {
        color: $gray-500;

        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

   .title {

    font-size: 2.5rem;
    font-weight: 700;

        @media screen and (max-width: 1023px){
            font-size: 1.6rem;
        }
    }
}



