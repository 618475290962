@import '../../assets/scss/variable.scss';

.accordion {
    //overflow: hidden;
    margin-bottom: 2rem;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        cursor: pointer;
        user-select: none;

        .icon {
            margin-right: 8px;
            color: $gray-500;
            font-size: 1.5rem;
        }

        .title {
            flex: 1;
            color: $gray-500;
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 0.7;
        }

        .toggleIcon {
            color: $gray-500;
            font-size: 1.5rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        gap: 2rem;
    }
}
