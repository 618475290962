.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
}

.connectButton {
  max-width: 50%;
}
