.gallery {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 2rem;
  @media screen and (min-width: 667px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.galleryCard {
  background: #fff;
  border: 0.085rem solid rgb(239, 240, 246);
  border-radius: 1.72rem;
  padding: 2rem 1rem;
}

.galleryCardTitle {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.14px;
  height: 50%;
}

.galleryCardValue {
  color: #000;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 1.3;
}
