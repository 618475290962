@import '../../assets/scss/variable';

.quickCommentReply {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 1rem;

    @media screen and (max-width: 667px) {
        width: 100%;
    }

    label {
        text-align: center;

        font-weight: 500;
        font-size: 1.25rem;
    }

    textarea {
        overflow: hidden;
        padding: 0.8rem;
        outline: none;
        border: 1px solid $gray-300;
        border-radius: 0.5rem;

        font-size: 1.2rem;
        line-height: 1.5;
        resize: none;

        &::placeholder {
            color: $gray-500;

            font-size: 1.25rem;
        }
    }
}
