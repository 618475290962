@import "../../../../../../assets/scss/variable";

.input {
  width: 100%;
  margin-top: 0;
  padding: 1rem 1.5rem;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1.1875rem;
}

.groupInputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: stretch;
  gap: 1.1875rem;
}

.previewContainer {
  width: 2.8125rem;
  height: 2.8125rem;
}
