.rdrCalendarWrapper.rdrDateRangeWrapper {
  font-family: Roboto, sans-serif;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrDayNumber,
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #000;
  font-size: 1.21rem;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge {
  color: #fff;
  font-size: 1.21rem;
  background: rgb(37, 51, 66);
}

/* .rdrWeekDay,
.rdrDay {
  width: calc(100% / 10);
}
.rdrWeekDay {
  transform: rotate(-90deg);
} */
