@import "../../assets/scss/variable";

.container {
  @media screen and (min-width: 667px) {
    margin: 2.5rem 0 0 2.5rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30rem;
  background-color: $gray-300;
  border-radius: 1rem;
  padding: 2rem 2rem 0 2rem;

  img {
    width: 22.2rem;
    height: 15.4rem;
  }

  @media screen and (min-width: 667px) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 22.5rem;
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    background-color: $gray-300;
    padding: 0 6rem 0 2.5rem;

    img {
      width: 29.3rem;
      height: 20.4rem;
    }
  }
}

.description {

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.9rem;
  letter-spacing: 0;
  margin-top: 0.5rem;
  @media screen and (min-width: 667px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.textContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (min-width: 667px) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
