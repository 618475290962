@import "../../../../assets/scss/variable";

.connectionBot {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  @media screen and (min-width: 667px) {
    background-color: $light-color;
  }
}

.pageDescription {
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1.25rem;
  @media screen and (max-width: 667px) {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }
}

.connectionBotText {
  font-size: 1.5rem;

  font-weight: 500;

  a {
    color: $purple;
    font-size: 1.5rem;

    font-weight: 500;
  }
}

.token {
  margin-top: 1.5rem;
  font-size: 1.7rem;

  font-weight: 600;
}

.saveChanges {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $purple;
  height: 4rem;
  margin-top: 1.5rem;
  border-radius: 0.8rem;
}

.saveChangesText {
  font-size: 1.25rem;

  font-weight: 600;
  color: $light-color;
}

.saveChangesButton {
  margin-left: 1rem;
  display: flex;
  font-size: 1rem;

  font-weight: 600;
  color: $purple;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10rem;
  background-color: $light-color;
  border-radius: 0.25rem;
}

.saveChangesButton:hover {
  background-color: #dfd5ff;
}

.saveOptimize {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0 0.3rem 0.2rem rgba(113, 116, 121, 0.3);
  background: $gradient-white-light-blue;
  color: $gray-500;
  border-radius: 0.5rem;

  font-size: 1.5rem;
  padding: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
  @media screen and (min-width: 667px) {
    width: 22.5rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  &.disabled {
    background: $color-light-gray;
    color: $gray-500;
  }

  &:hover {
    background: $gradient-blue-purple;
    color: $light-color;
  }

  &:disabled {
    background: $color-light-gray;
    color: $gray-500;

    &:hover {
      background: $color-light-gray;
      color: $gray-500;
      cursor: not-allowed;
    }
  }
}

