@import "../../../../assets/scss/variable";

.container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  background-color: $gray-300;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.description {
  margin: 0.75rem 0 1.5rem;
}

.inputWrapper {
  position: relative;
  width: 85%;
}

.input {
  margin-top: 0;
  width: 100%;
  padding-right: 45px;
}

.icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}
